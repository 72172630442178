import React, { useState } from "react";
import "assets/scss/SleepDoorCode.scss";

const SleepCheckinConfirmation = props => {
  const [roomData] = useState(
    props.location.state && props.location.state.roomData
      ? props.location.state.roomData
      : null
  );

  return (
    <div className="SleepDoorCode" id="SleepCancellation">
      <div
        className="wrapper"
        style={{ maxWidth: "600px", minHeight: "100vh" }}
      >
        <h2 className="title" style={{ textAlign: "center" }}>
          Your Reservation Details
        </h2>
        <p className="booking-id">
          You are staying in <span>{roomData && roomData.room_type}</span>
          <br /> in room number <span>{roomData && roomData.room_number}</span>
          <br /> The door code is{" "}
          <span>
            {roomData && roomData.door_code !== ""
              ? roomData.door_code
              : "No Code"}
          </span>
          <br /> check-in date <span>{roomData && roomData.check_in}</span>
        </p>
        <br />
        <div style={{ textAlign: "center" }}>
          <h2
            className="title"
            style={{ textAlign: "center", marginBottom: "0px" }}
          >
            You have been successfully checked-in!
          </h2>
        </div>
        {/* <h2 className="title">You’ve been successfully checked-in!</h2>
        <span>
          You will receive an email containing a detailed check-in instruction
        </span> */}
      </div>
    </div>
  );
};

export default SleepCheckinConfirmation;
