import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import SleepCustomerDetailForm from "pages/spacex/sleep/SleepCustomerDetailForm";
import { isValidDate } from "utils/date";
import {
  SPACEX_BOOKING_BUTTON,
  SLEEP_SINGLE_EMPLOYEE_OPTION,
  SLEEP_FAMILY_FRIEND_OPTION
} from "config/constants";
import SpacexNavbar from "pages/spacex/SpacexNavbar";
import {
  SLEEP_FRIEND_BNB_OPTION,
  SPACEX_LOGIN_EMAIL
} from "../../../config/constants";
import moment from "moment";

const SleepPersonalInfo = props => {
  const [isConfirmRedirect, setIsConfirmRedirect] = useState(false);
  const [isSearchRedirect, setIsSearchRedirect] = useState(false);
  const [pageContent] = useState({});
  const [countryOfPassport, setCountryOfPassport] = useState(false);
  const [checkinDate] = useState(props.match.params.checkinDate);
  const [checkoutDate] = useState(props.match.params.checkoutDate);
  const [roomId] = useState(props.match.params.roomId);
  const [handleValidDate, setHandleValidDate] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [chosenRoom, setChosenRoom] = useState(null);
  const [isMultiple, setIsMultiple] = useState(false);
  const [personInfo, setPersonInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: ""
  });

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      let validCheckin = isValidDate(checkinDate);
      let validCheckout = isValidDate(checkoutDate);
      commonIsValid(validCheckin, validCheckout);

      axios
        .post("/api/rooms/privilege", {
          email: sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
            ? sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
            : ""
        })
        .then(res => {
          console.log(res);
          res.data &&
            !res.data.error &&
            res.data.privilege &&
            setIsMultiple(res.data.privilege.multiple);
        })
        .catch(error => {
          console.log(error);
        });

      mounted.current = true;
    }

    if (
      props.location.state &&
      props.location.state.familyValue &&
      props.location.state.familyValue === SLEEP_FAMILY_FRIEND_OPTION &&
      moment(checkinDate).format("ddd") === "Tue"
    ) {
      setIsSearchRedirect(true);
    }
  }, [props.location.state, checkinDate, checkoutDate]);

  const commonIsValid = (validCheckin, validCheckout) => {
    (!validCheckin || !validCheckout) && setHandleValidDate(false);
  };

  const handleReservation = data => {
    handleCheckValidRoom(data);
  };

  const handleCheckValidRoom = data => {
    axios
      .post("/api/booking_button/validate_rooms", {
        checkin_date: checkinDate,
        checkout_date: checkoutDate,
        room_id: roomId,
        total_room: 1
      })
      .then(response => {
        response.data && response.data.status && setErrMsg("");
        response.data && response.data.status && handleSleepReservation(data);

        response.data &&
          !response.data.status &&
          setErrMsg("We are sorry, this room is not available anymore.");
      })
      .catch(error => {
        console.log(error);
        setErrMsg("We are sorry, this room is not available anymore.");
      });
  };

  const handleSleepReservation = data => {
    axios
      .post("/api/booking_button/book", {
        checkin_date: checkinDate,
        checkout_date: checkoutDate,
        room_id: roomId,
        total_room: 1,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.customer_email,
        cc: data.cc_email,
        phone: data.customer_phone,
        average_shift_worked: data.avgWork,
        position: data.position,
        code: SPACEX_BOOKING_BUTTON,
        housing_preference: data.housing_preference,
        requests: data.requests,
        family_friend: data.family_friend === 1 ? true : false,
        family_name: `${data.family_first_name} ${data.family_last_name}`,
        family_phone: data.family_phone,
        family_email: data.family_email,
        family_relationship: data.family_relationship,
        dob: `${data.dobYear}-${data.dobMonth}-${data.dobDay}`,
        friend_bnb:
          props.location.state.familyValue === SLEEP_FRIEND_BNB_OPTION
            ? true
            : false,
        host_email: data.host_spacex_email
      })
      .then(response => {
        // if (response.data && response.data.status !== "200") {
        //   setErrMsg("We are sorry, your booking is not successful.");
        //   return;
        // }

        setChosenRoom(response.data.room);
        setPersonInfo({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.customer_email,
          phone: data.customer_phone
        });
        setIsConfirmRedirect(true);
      })
      .catch(error => {
        console.log(error);
        setErrMsg("We are sorry, your booking is not successful.");
      });
  };

  const handleCountryOfPassport = value => {
    setCountryOfPassport(value);
  };

  if (isConfirmRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: "/sleep/confirmation",
          search: window.location.search,
          state: {
            roomArr: props.location.state.roomArr,
            chosenRoom: chosenRoom,
            personInfo: {
              checkin_date: checkinDate,
              checkout_date: checkoutDate,
              first_name: personInfo.first_name,
              last_name: personInfo.last_name,
              email: personInfo.email,
              phone: personInfo.phone
            }
          }
        }}
      />
    );
  }

  if (isSearchRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: "/sleep",
          search: window.location.search
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <SpacexNavbar />
      <SleepCustomerDetailForm
        handleReservation={handleReservation}
        pageType="customerDetails"
        pageContent={pageContent}
        countryOfPassport={countryOfPassport}
        handleCountryOfPassport={handleCountryOfPassport}
        handleValidDate={handleValidDate}
        errMsg={errMsg}
        familyValue={
          props.location &&
          props.location.state &&
          props.location.state.familyValue
            ? props.location.state.familyValue
            : SLEEP_SINGLE_EMPLOYEE_OPTION
        }
        roomArr={
          props.location && props.location.state && props.location.state.roomArr
            ? props.location.state.roomArr
            : []
        }
        isMultiple={isMultiple}
      />
    </React.Fragment>
  );
};

export default SleepPersonalInfo;
