import React from "react";
import MediaQuery from "react-responsive";
import SleepCustomerInfoWeb from "pages/spacex/sleep/SleepCustomerInfoWeb";
import SleepCustomerInfoMobile from "pages/spacex/sleep/SleepCustomerInfoMobile";
import { withRouter } from "react-router";
import { withFormik } from "formik";
import SleepCustomerValidationSchema from "pages/spacex/sleep/SleepCustomerValidationSchema.js";
import { compose } from "recompose";
import {
  SPACEX_LOGIN_EMAIL,
  SLEEP_FAMILY_FRIEND_OPTION
} from "config/constants";
import { COUNTRIES } from "config/data/countries";

const MyForm = props => {
  const handleOpenClose = value => {
    props.setFieldValue("showConfirmation", value);
  };

  return (
    <React.Fragment>
      <MediaQuery minWidth={1025}>
        <SleepCustomerInfoWeb
          pageName={props.values.pageName}
          {...props}
          handleOpenClose={handleOpenClose}
          showConfirmation={props.values.showConfirmation}
          isLoading={props.values.isLoading}
        />
      </MediaQuery>
      <MediaQuery maxWidth={1024}>
        <SleepCustomerInfoMobile
          {...props}
          handleOpenClose={handleOpenClose}
          showConfirmation={props.values.showConfirmation}
          isLoading={props.values.isLoading}
          isContinue={props.isContinue}
          countryOfPassport={props.countryOfPassport}
          handleCountryOfPassport={props.handleCountryOfPassport}
          countryFile={COUNTRIES}
        />
      </MediaQuery>
    </React.Fragment>
  );
};

const normalizeData = values => {
  let dataResult = { data: {} };
  dataResult.data["customer_email"] = values.email;
  dataResult.data["first_name"] = values.firstName;
  dataResult.data["last_name"] = values.lastName;
  dataResult.data["customer_phone"] =
    values.mobilePrefix + "" + values.mobileNo;
  dataResult.data["cc_email"] = values.cc_email;
  dataResult.data["avgWork"] = values.avgWork;
  dataResult.data["position"] = values.position;
  dataResult.data["housing_preference"] = values.housing_preference;
  dataResult.data["requests"] = values.requests;
  dataResult.data["dob"] =
    values.dob && values.dob !== "" ? values.dob.format("YYYY-MM-DD") : "";
  dataResult.data["dobDay"] = values.dobDay;
  dataResult.data["dobMonth"] = values.dobMonth;
  dataResult.data["dobYear"] = values.dobYear;
  dataResult.data["family_friend"] = values.family_friend;
  dataResult.data["family_first_name"] = values.family_first_name;
  dataResult.data["family_last_name"] = values.family_last_name;
  dataResult.data["family_relationship"] = values.family_relationship;
  dataResult.data["family_email"] = values.family_email;
  dataResult.data["family_phone"] =
    values.family_phonePrefix + " " + values.family_phone;
  dataResult.data["host_spacex_email"] = values.host_spacex_email;
  return dataResult;
};

const getLastData = () => JSON.parse(sessionStorage.getItem("last"));
const getName = lastData =>
  lastData && lastData.name ? lastData.name.split(" ") : [];
const getFirstName = name =>
  name.length >= 2 ? name.slice(0, -1).join(" ") : name.slice(-1).join(" ");
const getLastName = name => (name.length < 2 ? "" : name.slice(-1).join(" "));

const SleepCustomerDetailForm = compose(
  withRouter,
  withFormik({
    mapPropsToValues: props => {
      let last = getLastData();
      let name = getName(last);
      let phone = last && last.phone ? last.phone : "";
      let getPhone = phone.charAt(0);
      phone = Number(getPhone) === 1 ? phone.substring(1) : phone;

      return {
        firstName: getFirstName(name),
        lastName: getLastName(name),
        mobileCountryFlag: "us",
        mobilePrefix: "1",
        mobileNo: phone,
        email: sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
          ? sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
          : "",
        cc_email: "",
        isChecked: false,
        showConfirmation: false,
        isLoading: false,
        isConfirm: false,
        avgWork: "",
        position: "",
        housing_preference: "no_preference",
        requests: "",
        family_friend: props.familyValue === SLEEP_FAMILY_FRIEND_OPTION ? 1 : 0,
        family_first_name: "",
        family_last_name: "",
        family_relationship: "",
        family_email: "",
        familyMobileCountryFlag: "us",
        family_phonePrefix: "1",
        family_phone: "",
        dobDay: "00",
        dobMonth: "00",
        dobYear: "0000",
        host_spacex_email: ""
      };
    },

    validationSchema: props => SleepCustomerValidationSchema(props),

    handleSubmit: (values, props) => {
      props.setFieldValue("isLoading", true);
      let data = normalizeData(values);
      props.props.handleReservation(data.data);
    },

    displayName: "BasicForm"
  })
)(MyForm);

export default SleepCustomerDetailForm;
