import React, { useEffect, useState } from "react";
import "assets/scss/SleepDoorCode.scss";
import Axios from "axios";
import { Button } from "@material-ui/core";

const SleepCheckin = ({ history, match }) => {
  const [room, setRoom] = useState(null);
  const [flag, setFlag] = useState(false);
  const [showCheckin, setShowCheckin] = useState(false);
  const [showCheckinCancel, setShowCheckinCancel] = useState(false);
  const [status, setStatus] = useState(null);
  const CHECK_RESERVATION = "/public/reservation/info";

  useEffect(() => {
    if (!flag) {
      Axios.post(CHECK_RESERVATION, {
        bookingCode: window.location.pathname.split("/")[3]
      })
        .then(res => res.data && setRoom(res.data.data))
        .catch(err => {
          setRoom(null);
          setStatus("not found");
        });
      setFlag(true);
    }
  }, [flag]);

  const checkinSleepBooking = () => {
    Axios.post("/public/reservation/checkinSleepBooking", {
      bookingCode: window.location.pathname.split("/")[3]
    })
      .then(
        res =>
          res.data &&
          history.push({
            pathname: "/sleep/checkin-confirmation",
            state: { roomData: room }
          })
      )
      .catch(err => history.push("/sleep/checkin-confirmation"));
  };

  const checkinCancelSleepBooking = () => {
    Axios.post("/public/reservation/cancelSleepBooking", {
      bookingCode: window.location.pathname.split("/")[3]
    })
      .then(res => res.data && history.push("/sleep/checkin-cancel"))
      .catch(err => history.push("/sleep/checkin-cancel"));
  };

  return (
    <div className="SleepDoorCode">
      <div className="wrapper">
        {status === "not found" ? (
          <h2 className="title">Reservation Not Found</h2>
        ) : (
          <>
            <h2 className="title" style={{ textAlign: "center" }}>
              Your Reservation Details
            </h2>
            <p className="booking-id">
              You are staying in <span>{room && room.room_type}</span>
              {room && room.reservation_status === "checked in" && (
                <>
                  <br /> in room number <span>{room && room.room_number}</span>
                  <br /> The door code is{" "}
                  <span>
                    {room && room.door_code !== "" ? room.door_code : "No Code"}
                  </span>
                </>
              )}
              <br /> check-in date <span>{room && room.check_in}</span>
            </p>
            <br />

            {room && room.reservation_status === "checked in" && (
              <div style={{ textAlign: "center" }}>
                <h2
                  className="title"
                  style={{ textAlign: "center", marginBottom: "0px" }}
                >
                  You have been successfully checked in!
                </h2>
              </div>
            )}

            {room && room.reservation_status === "canceled" && (
              <div style={{ textAlign: "center" }}>
                <h2
                  className="title"
                  style={{ textAlign: "center", marginBottom: "0px" }}
                >
                  Your reservation has been canceled.
                </h2>
              </div>
            )}

            {!showCheckin &&
              !showCheckinCancel &&
              room &&
              room.reservation_status !== "checked in" &&
              room.reservation_status !== "canceled" && (
                <div style={{ textAlign: "center" }}>
                  <h2
                    className="title"
                    style={{ textAlign: "center", marginBottom: "0px" }}
                  >
                    Kindly press the button below <br />
                    to check-out or cancel your reservation
                  </h2>
                  <Button
                    className="btnContinue btnCheckoutYes"
                    onClick={() => setShowCheckin(true)}
                  >
                    Check-In Reservation
                  </Button>
                  <Button
                    className="btnContinue btnCheckoutDanger"
                    onClick={() => setShowCheckinCancel(true)}
                    style={{}}
                  >
                    Cancel Reservation
                  </Button>
                </div>
              )}
            {showCheckin &&
              room &&
              room.reservation_status !== "checked in" &&
              room.reservation_status !== "canceled" && (
                <div style={{ textAlign: "center" }}>
                  <h2
                    className="title"
                    style={{ textAlign: "center", marginBottom: "0px" }}
                  >
                    Are you sure you want to proceed with the check-in?
                  </h2>
                  <Button
                    className="btnContinue btnCheckoutYes"
                    onClick={() => checkinSleepBooking()}
                  >
                    Yes
                  </Button>
                  <Button
                    className="btnContinue btnCheckoutNo"
                    onClick={() => setShowCheckin(false)}
                  >
                    No
                  </Button>
                </div>
              )}
            {showCheckinCancel &&
              room &&
              room.reservation_status !== "checked in" &&
              room.reservation_status !== "canceled" && (
                <div style={{ textAlign: "center" }}>
                  <h2
                    className="title"
                    style={{ textAlign: "center", marginBottom: "0px" }}
                  >
                    Are you sure you want to cancel your reservation?
                  </h2>
                  <Button
                    className="btnContinue btnCheckoutDanger btnCheckinCancelResConfirmation"
                    onClick={() => checkinCancelSleepBooking()}
                  >
                    Yes
                  </Button>
                  <Button
                    className="btnContinue btnCheckoutNo"
                    onClick={() => setShowCheckinCancel(false)}
                  >
                    No
                  </Button>
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default SleepCheckin;
