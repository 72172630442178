import React from "react";
import "assets/scss/SleepDoorCode.scss";

const SleepCancelConfirmation = () => {
  return (
    <div className="SleepDoorCode" id="SleepCancellation">
      <div
        className="wrapper"
        style={{ maxWidth: "600px", minHeight: "100vh" }}
      >
        <h2 className="title">Cancellation Confirmed</h2>
        <p className="booking-id">Your hotel room has been cancelled.</p>
      </div>
    </div>
  );
};

export default SleepCancelConfirmation;
