import React from "react";
import "assets/scss/SleepDoorCode.scss";

const SleepDoorCode = () => {
  return (
    <div className="SleepDoorCode">
      <div className="wrapper">
        <h2 className="title">Your Reservation Details</h2>
        <p className="booking-id">
          You will be staying in room <span>{`{room_type}`}</span> in room
          number <span>{`{room_number}`}</span>.
        </p>
        <p className="booking-id">
          The door code is <span>{`{code}`}</span>.
        </p>
      </div>
    </div>
  );
};

export default SleepDoorCode;
