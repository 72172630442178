import React from "react";
import { Grid, Button, Modal, Card } from "@material-ui/core";
import { SPACEX_LOGIN_EMAIL } from "../../config/constants";

const LogoutConfirmationModal = ({
  showConfirmation,
  handleClose,
  history
}) => {
  const logout = () => {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem(SPACEX_LOGIN_EMAIL);
    sessionStorage.removeItem("lastTemp");
    sessionStorage.removeItem("last");
    window.location.href = "/";
  };
  return (
    <React.Fragment>
      <Modal open={showConfirmation}>
        <Card className="confirmationBox">
          <Grid item={true} sm={12}>
            <div>
              <React.Fragment>
                <div className="confirmationBoxTitle">
                  Are you sure want to log out?
                </div>
              </React.Fragment>

              <React.Fragment>
                <Button className="btnBack" onClick={() => handleClose(false)}>
                  No
                </Button>
                <Button
                  className="btnContinue mobileConfirmBtn mobileBusConfirmButton"
                  onClick={() => logout()}
                >
                  Yes
                </Button>
              </React.Fragment>
            </div>
          </Grid>
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export default LogoutConfirmationModal;
