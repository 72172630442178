import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
//import { SPACEX_LOGIN_EMAIL } from "../../config/constants";
import "./SpacexNavbar.css";
import LogoutConfirmationModal from "../spacex/LogoutConfirmationModal";
import Tooltip from "@material-ui/core/Tooltip";
import MediaQuery from "react-responsive";

const SpacexNavbar = ({ location }) => {
  const pn = location.pathname;
  // if (!(pn === "/" || pn === "/sleep" || pn === "/fly")) return null;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleClose = () => {
    setShowConfirmation(false);
  };

  let pathname = location.pathname.split("/");

  return (
    <>
      <nav className="SpacexNavbar">
        <h1>
          {pathname.length > 1 && pathname[0] === "" && pathname[1] === ""
            ? "Starbase"
            : pathname.length > 1 && pathname[1] && pathname[1] === "signature"
            ? "Signature Required"
            : pathname.length > 1 && pathname[1] && pathname[1] === "sleep"
            ? "Book an accommodation"
            : pathname.length > 1 && pathname[1] && pathname[1] === "fly"
            ? "Book a charter flight"
            : pathname.length > 1 && pathname[1] && pathname[1] === "hovercraft"
            ? "Book a hovercraft"
            : pathname.length > 1 && pathname[1] && pathname[1] === "info"
            ? "Important Information"
            : pathname.length > 1 &&
              pathname[1] &&
              pathname[1] === "car" &&
              pathname[2] &&
              pathname[2] === "confirmation"
            ? "Get a Car"
            : pathname.length > 1 && pathname[1] && pathname[1] === "car"
            ? "Book a car"
            : ""}
        </h1>
        <MediaQuery minDeviceWidth={768}>
          <ul>
            <li>
              <Link to="/">My Bookings</Link>
            </li>
            <li>
              <Link to="/fly">Flights</Link>
            </li>
            <li>
              <Link to="/car">Cars</Link>
            </li>
            <li>
              <Link to="/sleep">Rooms</Link>
            </li>
            <li>
              <Link to="/hovercraft">Hovercraft</Link>
            </li>
            <Tooltip title="Help" placement="bottom">
              <li>
                <a
                  href={
                    pn.startsWith("/fly")
                      ? "mailto:flights@spacex.com"
                      : "mailto:info@bocachicatravel.com"
                  }
                  style={{ whiteSpace: "nowrap" }}
                >
                  <i className="fa fa-info-circle"></i>
                </a>
              </li>
            </Tooltip>
            <Tooltip title="Logout" placement="bottom">
              <li className="nav-logout">
                <span onClick={() => setShowConfirmation(true)}>
                  <i className="fa fa-sign-out"></i>
                </span>
              </li>
            </Tooltip>
          </ul>
        </MediaQuery>
        <MediaQuery maxWidth={780}>
          <ul className="ul-top">
            <li className="li-home">
              <Link to="/">
                <i className="fa fa-home"></i>
              </Link>
            </li>
            <li className="li-product">
              <Link to="/fly">
                <i className="fa fa-plane"></i>
              </Link>
            </li>
            <li className="li-product">
              <Link to="/car">
                <i className="fa fa-car"></i>
              </Link>
            </li>
            <li className="li-product">
              <Link to="/sleep">
                <i className="fa fa-bed"></i>
              </Link>
            </li>
            <li className="li-product">
              <Link to="/hovercraft">
                <i className="fa fa-ship"></i>
              </Link>
            </li>
            {/* <Tooltip title="Help" placement="bottom">
              <li>
                <a
                  href={
                    pn === "/fly"
                      ? "mailto:flights@spacex.com"
                      : "mailto:info@bocachicatravel.com"
                  }
                  style={{ whiteSpace: "nowrap" }}
                >
                  <i className="fa fa-info-circle"></i>
                </a>
              </li>
            </Tooltip> */}
            <Tooltip title="Logout" placement="bottom">
              <li className="nav-logout">
                <span onClick={() => setShowConfirmation(true)}>
                  <i className="fa fa-sign-out"></i>
                </span>
              </li>
            </Tooltip>
          </ul>
        </MediaQuery>
      </nav>
      <div className="underline">
        <div className="underline-inner"></div>
      </div>
      <LogoutConfirmationModal
        showConfirmation={showConfirmation}
        handleClose={handleClose}
      />
    </>
  );
};

export default withRouter(SpacexNavbar);
