import React from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhonePrefix = ({
  handlePhonePrefix,
  prefixValue,
  value,
  handlePhoneFlag,
  mobileCountryFlag
}) => {
  return (
    <React.Fragment>
      <ReactPhoneInput
        country={"us"}
        inputClass="MuiInputBase-formControl"
        defaultCountry={prefixValue !== "" ? mobileCountryFlag : ""}
        onChange={(value, data) => {
          handlePhoneFlag(data.countryCode);
          handlePhonePrefix(value);
        }}
        style={{ width: "80px" }}
        inputProps={{
          readOnly: true
        }}
      />
      <span className="countryCode">
        {prefixValue !== "" ? "+" + prefixValue : ""}
      </span>
    </React.Fragment>
  );
};

export default PhonePrefix;
