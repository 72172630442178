import React from "react";
import axios from "axios";
import FlyCustomerDetailForm from "pages/spacex/fly/FlyCustomerDetailForm";
import SpacexNavbar from "pages/spacex/SpacexNavbar";
import SignaturePage from "../SignaturePage";
import _ from "lodash";
import moment from "moment";
class FlyPersonalInfo extends React.PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      showAlertBox: false,
      pageContent: {},
      isContinue: false,
      utm_medium: null,
      utm_campaign: null,
      flightId: props.match.params.flightId
        ? props.match.params.flightId
        : null,
      isBrownsville:
        props.match.params.isBrownsville &&
        props.match.params.isBrownsville === "1",
      showNda: false,
      bookingData: {},
      errMsg: "",
      additionalBaggage: false,
      isCharter: this.props.location.state.isCharter === "1" ? true : false,
      flightArr: []
    };
  }

  componentDidMount() {
    let self = this;
    let params = {
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment()
        .add(62, "days")
        .format("YYYY-MM-DD")
    };

    axios
      .post("/api/airplane/availability", params)
      .then(function(response) {
        response.data &&
          !response.data.error &&
          self.setState({ flightArr: response.data.flights });

        response.data &&
          response.data.error &&
          self.setState({ flightArr: [] });
      })
      .catch(function(error) {
        console.log(error);
      });
    this._isMounted = true;
  }

  componentWillMount() {
    this._isMounted = false;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSetSignature = signature => {
    this.handleReservation({ ...this.state.bookingData, signature }, false);
  };

  handleAdditionalBaggage = () =>
    this.setState({ additionalBaggage: !this.state.additionalBaggage });

  shouldShowNda = () =>
    this.props.location.state &&
    this.props.location.state.isNda === "1" &&
    this.state.showNda === false;

  handleReservation = (data, storeParams = true) => {
    let params = {
      route_id: this.state.flightId,
      name: data.customer_name,
      first_name: data.customer_first_name,
      last_name: data.customer_last_name,
      phone: data.customer_phone,
      email: data.customer_email,
      cc_email: data.cc_email,
      manager_email: data.manager_email,
      report: data.report,
      signature: data.signature || "",
      purpose: data.purpose,
      length_of_stay: data.length_of_stay,
      inventory_weight: data.inventory_weight,
      inventory_dimension_h: data.inventory_dimension_h,
      inventory_dimension_w: data.inventory_dimension_w,
      inventory_dimension_l: data.inventory_dimension_l,
      hazardous: data.hazardous,
      additional_weight: data.additional_weight,
      additional_dimension_h: data.additional_dimension_h,
      additional_dimension_w: data.additional_dimension_w,
      additional_dimension_l: data.additional_dimension_l,
      charter: data.charter,
      dob: `${data.dobYear}-${data.dobMonth}-${data.dobDay}`,
      weight: data.weight,
      baggage: data.baggage,
      animal: data.animal,
      animal_type: data.animal_type,
      animal_size: data.animal_size,
      animal_name: data.animal_name,
      animal_service: data.animal_service === "yes" ? true : false,
      animal_id: data.animal_id,
      animal_weight: data.animal_weight,
      animal_detail: data.animal_detail,
      family_friend: data.family_friend,
      family_name: `${data.family_first_name} ${data.family_last_name}`,
      family_first_name: data.family_first_name,
      family_last_name: data.family_last_name,
      family_relationship: data.family_relationship,
      family_email: data.family_email,
      family_phone: data.family_phone,
      shuttle_bus: data.shuttle_bus !== "" ? data.shuttle_bus : false
    };
    // if (this.state.isCharter) {
    //   params["charter"] = this.state.isCharter;
    // }

    if (
      data.manager_email !== sessionStorage.getItem("manager_email") &&
      data.manager_email !== ""
    ) {
      sessionStorage.setItem("manager_email", data.manager_email);
    }

    if (storeParams) {
      this.setState({ bookingData: data });

      if (this.shouldShowNda()) {
        this.setState({ showNda: true });
        return;
      }
    }
    axios
      .post("/api/airplane/book", params)
      .then(response => {
        if (response.data && response.data.error) {
          alert(response.data.message);
          return;
        }

        response.data &&
          !response.data.error &&
          response.data.flights &&
          this.props.history.push({
            pathname: "/fly/confirmation",
            search: window.location.search,
            state: { chosenFlight: response.data.flights }
          });

        let err = [];
        _.forOwn(response.data.errors, (value, key) => {
          err.push(value);
        });
        response.data &&
          response.data.error &&
          this.setState({
            errMsg: err.join(",")
          });
      })
      .catch(error =>
        this.setState({
          errMsg: "We are sorry, your booking is not successful"
        })
      );
  };

  handleClose = () => {
    this._isMounted && this.setState({ showAlertBox: false });
  };

  handleGoBack = () => {
    this._isMounted && this.setState({ isGoBack: true });
  };

  handleClickContinue = value => {
    this.setState({ isContinue: value });
  };

  render() {
    let { isBrownsville, errMsg, showNda, additionalBaggage } = this.state;

    if (showNda)
      return (
        <SignaturePage
          handleReservation={this.handleReservation}
          setSignature={this.handleSetSignature}
          errMsg={errMsg}
        />
      );

    return (
      <React.Fragment>
        <SpacexNavbar />
        <FlyCustomerDetailForm
          handleReservation={this.handleReservation}
          handleGoBack={this.handleGoBack}
          pageType="customerDetails"
          pageContent={this.state.pageContent}
          handleClickContinue={this.handleClickContinue}
          countryOfPassport={this.state.countryOfPassport}
          handleCountryOfPassport={this.handleCountryOfPassport}
          utmMedium={this.state.utm_medium}
          utmCampaign={this.state.utm_campaign}
          isBrownsville={isBrownsville}
          additionalBaggage={additionalBaggage}
          handleAdditionalBaggage={this.handleAdditionalBaggage}
          errMsg={errMsg}
          submitText={
            this.props.location.state && this.props.location.state.isNda === "1"
              ? "Continue"
              : "Confirm Flight!"
          }
          isCharter={this.state.isCharter}
          flightId={this.state.flightId}
          flightArr={this.state.flightArr}
          isMultiple={this.props.location.state.isMultiple}
          userData={this.props.location.state.userData}
        />
      </React.Fragment>
    );
  }
}

export default FlyPersonalInfo;
