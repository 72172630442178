import React from "react";
import MediaQuery from "react-responsive";
import FlyCustomerInfoWeb from "pages/spacex/fly/FlyCustomerInfoWeb";
import FlyCustomerInfoMobile from "pages/spacex/fly/FlyCustomerInfoMobile";
import { withRouter } from "react-router";
import { withFormik } from "formik";
import FlyValidationSchema from "./FlyValidationSchema.js";
import { compose } from "recompose";
import { COUNTRIES } from "config/data/countries";
import { SPACEX_LOGIN_EMAIL } from "config/constants";
class MyForm extends React.PureComponent {
  handleOpenClose = value => {
    this.props.setFieldValue("showConfirmation", value);
  };
  handleCloseModal = () => {
    this.props.setFieldValue("showConfirmation", false);
  };
  handleContactInfo = value => {
    let { setFieldValue, values } = this.props;
    if (value) {
      setFieldValue("adultInfo[0].title", values.gender);
      setFieldValue("adultInfo[0].firstName", values.firstName);
      setFieldValue("adultInfo[0].lastName", values.lastName);
      setFieldValue("adultInfo[0].mobileCountryFlag", values.mobileCountryFlag);
      setFieldValue("adultInfo[0].mobilePrefix", values.mobilePrefix);
      setFieldValue("adultInfo[0].mobileNo", values.mobileNo);
      setFieldValue("adultInfo[0].email", values.email);
      setFieldValue("adultInfo[0].cc_email", values.cc_email);
      setFieldValue("adultInfo[0].manager_email", values.manager_email);
    } else {
      setFieldValue("adultInfo[0].title", "Mr.");
      setFieldValue("adultInfo[0].firstName", "");
      setFieldValue("adultInfo[0].lastName", "");
      setFieldValue("adultInfo[0].mobileCountryFlag", "mm");
      setFieldValue("adultInfo[0].mobilePrefix", "95");
      setFieldValue("adultInfo[0].mobileNo", "");
      setFieldValue("adultInfo[0].email", "");
      setFieldValue("adultInfo[0].cc_email", "");
      setFieldValue("adultInfo[0].manager_email", "");
    }
    setFieldValue("isChecked", value);
  };

  render() {
    return (
      <React.Fragment>
        <MediaQuery minWidth={1025}>
          <FlyCustomerInfoWeb
            returnFlightDetailInfo={this.props.returnFlightDetailInfo}
            pageName={this.props.values.pageName}
            {...this.props}
            handleOpenClose={this.handleOpenClose}
            showConfirmation={this.props.values.showConfirmation}
            handleContactInfo={this.handleContactInfo}
            isLoading={this.props.values.isLoading}
            handleGoBack={this.props.handleGoBack}
            pageContent={this.props.pageContent}
            submitText={this.props.submitText}
            isCharter={this.props.isCharter}
            flightId={this.props.flightId}
            flightArr={this.props.flightArr}
            isMulitple={this.props.isMulitple}
            userData={this.props.userData}
          />
        </MediaQuery>
        <MediaQuery maxWidth={1024}>
          <FlyCustomerInfoMobile
            pageName={this.props.values.pageName}
            {...this.props}
            handleOpenClose={this.handleOpenClose}
            handleCloseModal={this.handleCloseModal}
            showConfirmation={this.props.values.showConfirmation}
            handleContactInfo={this.handleContactInfo}
            isLoading={this.props.values.isLoading}
            pageContent={this.props.pageContent}
            isContinue={this.props.isContinue}
            handleClickContinue={this.props.handleClickContinue}
            countryOfPassport={this.props.countryOfPassport}
            handleCountryOfPassport={this.props.handleCountryOfPassport}
            countryFile={COUNTRIES}
            submitText={this.props.submitText}
            isCharter={this.props.isCharter}
            flightId={this.props.flightId}
            flightArr={this.props.flightArr}
            isMulitple={this.props.isMulitple}
            userData={this.props.userData}
          />
        </MediaQuery>
      </React.Fragment>
    );
  }
}

const normalizeData = values => {
  let dataResult = { data: {} };
  dataResult.data["customer_email"] = values.multiEmail;
  dataResult.data["cc_email"] = values.cc_email;
  dataResult.data["manager_email"] = values.manager_email;
  dataResult.data["customer_name"] = values.firstName + " " + values.lastName;
  dataResult.data["customer_first_name"] = values.firstName;
  dataResult.data["customer_last_name"] = values.lastName;
  dataResult.data["customer_phone"] =
    values.mobilePrefix + "" + values.mobileNo;
  dataResult.data["purpose"] = values.purpose;
  dataResult.data["report"] = values.report;
  dataResult.data["length_of_stay"] = values.length_of_stay;
  dataResult.data["inventory_weight"] = values.inventory_weight;
  dataResult.data["inventory_dimension_h"] = values.inventory_dimension_h;
  dataResult.data["inventory_dimension_w"] = values.inventory_dimension_w;
  dataResult.data["inventory_dimension_l"] = values.inventory_dimension_l;
  dataResult.data["hazardous"] = values.hazardous;
  dataResult.data["additional_weight"] = values.additional_weight;
  dataResult.data["additional_dimension_h"] = values.additional_dimension_h;
  dataResult.data["additional_dimension_w"] = values.additional_dimension_w;
  dataResult.data["charter"] = values.charter;
  dataResult.data["dob"] =
    values.dob && values.dob !== "" ? values.dob.format("MM/DD/YYYY") : "";
  dataResult.data["dobDay"] = values.dobDay;
  dataResult.data["dobMonth"] = values.dobMonth;
  dataResult.data["dobYear"] = values.dobYear;
  dataResult.data["weight"] = values.weight;
  dataResult.data["baggage"] = values.baggage;
  dataResult.data["animal"] = values.animal;
  dataResult.data["animal_type"] = values.animal_type;
  dataResult.data["animal_size"] = values.animal_size;
  dataResult.data["animal_id"] = values.animal_id;
  dataResult.data["animal_service"] = values.animal_service;
  dataResult.data["animal_weight"] = values.animal_weight;
  dataResult.data["animal_name"] = values.animal_name;
  dataResult.data["animal_detail"] = values.animal_detail;
  dataResult.data["family_friend"] = values.family_friend;
  //dataResult.data["family_name"] = values.family_name;
  dataResult.data["family_first_name"] = values.family_first_name;
  dataResult.data["family_last_name"] = values.family_last_name;
  dataResult.data["family_relationship"] = values.family_relationship;
  dataResult.data["family_email"] = values.family_email;
  dataResult.data["family_phone"] =
    values.family_phonePrefix + " " + values.family_phone;
  dataResult.data["shuttle_bus"] = values.shuttle_bus;
  return dataResult;
};

const getLastData = () => JSON.parse(sessionStorage.getItem("last"));
const getName = lastData =>
  lastData && lastData.name ? lastData.name.split(" ") : [];
const getFirstName = name =>
  name.length >= 2 ? name.slice(0, -1).join(" ") : name.slice(-1).join(" ");
const getLastName = name => (name.length < 2 ? "" : name.slice(-1).join(" "));

const FlyCustomerDetailForm = compose(
  withRouter,
  withFormik({
    mapPropsToValues: props => {
      let last = getLastData();
      let name = getName(last);
      let phone = last && last.phone ? last.phone : "";
      let getPhone = phone.charAt(0);
      phone = Number(getPhone) === 1 ? phone.substring(1) : phone;

      return {
        gender: "Mr.",
        firstName:
          !props.isMultiple && props.userData && props.userData.first_name
            ? props.userData.first_name
            : getFirstName(name),
        lastName:
          !props.isMultiple && props.userData && props.userData.last_name
            ? props.userData.last_name
            : getLastName(name),
        mobileCountryFlag: "us",
        mobilePrefix: "1",
        mobileNo: phone,
        multiEmail: sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
          ? sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
          : "",
        email: "",
        cc_email: "",
        manager_email: sessionStorage.getItem("manager_email")
          ? sessionStorage.getItem("manager_email")
          : "",
        utmCampaign: props.utmCampaign
          ? props.utmCampaign === "corporatepilot"
            ? props.utmCampaign
            : null
          : null,
        bookingId: "",
        paymentMethod: "",
        isChecked: false,
        showConfirmation: false,
        isLoading: false,
        isConfirm: false,
        inventory_weight: 0,
        inventory_dimension_h: 0,
        inventory_dimension_w: 0,
        inventory_dimension_l: 0,
        baggage_type: "ft",
        hazardous: "",
        additional_weight: 0,
        additional_dimension_h: 0,
        additional_dimension_w: 0,
        additional_dimension_l: 0,
        report: last && last.report ? last.report : "",
        purpose: last && last.purpose ? last.purpose : "",
        length_of_stay: "",
        charter: props.isCharter,
        dob: "",
        dobDay: "00",
        dobMonth: "00",
        dobYear: "0000",
        weight: "",
        baggage: "",
        animal: false,
        animal_id: "",
        animal_type: "",
        animal_size: "",
        animal_name: "",
        animal_service: "",
        animal_weight: "",
        animal_detail: "",
        family_friend: 0,
        //family_name: "",
        family_first_name: "",
        family_last_name: "",
        family_relationship: "",
        family_email: "",
        familyMobileCountryFlag: "us",
        family_phonePrefix: "1",
        family_phone: "",
        shuttle_bus: ""
      };
    },

    validationSchema: props => FlyValidationSchema(props),

    handleSubmit: (values, props) => {
      props.setFieldValue("isLoading", true);
      let data = normalizeData(values);
      props.props.handleReservation(data.data);
    },

    displayName: "BasicForm"
  })
)(MyForm);

export default FlyCustomerDetailForm;
