import React, { useState, useEffect } from "react";
import Axios from "axios";
import moment from "moment";
import "assets/scss/SleepDoorCode.scss";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const DoorCodeContainer = props => {
  return (
    <div className="SleepDoorCode">
      <div className="wrapper">
        <h2 className="title" style={{ textAlign: "center" }}>Your Reservation Details</h2>
        <div className="wrapper">{props.children}</div>
      </div>
    </div>
  );
};

const UpdateSleep = ({ status, bookingCode }) => {
  const isWaitingForGuest = () => status === "waiting for guest";

  return (
    isWaitingForGuest() && (
      <div>
        <p className="booking-id" style={{ paddingTop: "20px" }}>
          To update your reservation:
        </p>
        <div>
          <Link to={`/sleep/checkin/${bookingCode}`}>
            <Button className="btnChoose">I'm checked in</Button>
          </Link>
        </div>
        {/*<div>*/}
        {/*  <Button className="btnChoose">Please extend my reservation</Button>*/}
        {/*</div>*/}
      </div>
    )
  );
};

const SleepDoorCode = ({ history, match }) => {
  const CHECK_RESERVATION = "/public/reservation/info";

  const [room, setRoom] = useState(false);
  const getDate = date => moment(date).format("dddd, MMMM D");

  useEffect(() => {
    Axios.post(CHECK_RESERVATION, {
      bookingCode: match.params.bookingCode
    })
      .then(res => res.data && setRoom(res.data.data))
      .catch(err => setRoom({}));
    //eslint-disable-next-line
  }, []);

  if (!room) {
    return null;
  }

  switch (room && room.status) {
    case "not finalized":
      return (
        <DoorCodeContainer>
          <p className="booking-id">
            Your room assignment and door code have not been finalized yet and
            will be ready by
            <span>
              {room.access_time} {room.timezone}
            </span>
            on <span>{getDate(room.check_in)}</span>
          </p>
        </DoorCodeContainer>
      );
    case "finalized":
      return (
        <DoorCodeContainer>
          <p className="booking-id">
            You will be staying in room <span>{room.room_type}</span> in room
            number <span>{room.room_number}</span>.
          </p>
          <p className="booking-id">
            The door code is <span>{room.door_code}</span>.
          </p>
          <UpdateSleep
            status={room.reservation_status}
            bookingCode={match.params.bookingCode}
          />
        </DoorCodeContainer>
      );
    case "not been generated":
      return (
        <DoorCodeContainer>
          <p className="booking-id">
            You will be staying in room <span>{room.room_type}</span> in room
            number <span>{room.room_number}</span>.
          </p>
          <p className="booking-id">
            Your door code has not been generated. Please contact the travel
            team.
          </p>
        </DoorCodeContainer>
      );
    default:
      return (
        <div className="SleepDoorCode">
          <div className="wrapper">
            <h2 className="title">Reservation Not Found</h2>
          </div>
        </div>
      );
  }
};

export default SleepDoorCode;
