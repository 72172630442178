import React from "react";
import MediaQuery from "react-responsive";
import PhonePrefixPopupMobile from "components/mobile/PhonePrefixPopupMobile";
import { TextField } from "@material-ui/core";

const PhonePrefixTextField = ({
  name,
  value,
  handleFocus,
  handleChange,
  otherAirport,
  isOpen,
  filterList,
  handleClick,
  handleCloseMobile,
  type,
  inputName,
  handleCountryOfPassport,
  infoArr,
  setFieldValue,
  fieldName,
  autoFocusRef,
  inputAutoFocus,
  message
}) => {
  return (
    <React.Fragment>
      {/* Tablet or Mobile */}
      <MediaQuery maxWidth={1024}>
        <TextField
          id="PreFixTextField"
          onFocus={handleFocus(name, otherAirport)}
        ></TextField>
        <div
          onClick={handleFocus(name, otherAirport)}
          className="searchBox mobile-from-to"
        >
          <div className="MuiFormControl-root prefixBox">
            <span className="MuiInputBase-input">
              {type === "country" ? value.split("#")[1] : value}
            </span>
            <input
              type="hidden"
              value={type === "country" ? value.split("#")[0] : ""}
            />
          </div>
        </div>
        <PhonePrefixPopupMobile
          name={inputName}
          value={infoArr}
          isOpen={isOpen}
          // value={type === "country" ? value.split("#")[0] : value}
          filterList={filterList}
          handleClick={handleClick}
          // name={name}
          otherAirport={otherAirport}
          handleChange={handleChange}
          handleCloseMobile={handleCloseMobile}
          type={type}
          handleCountryOfPassport={handleCountryOfPassport}
          setFieldValue={setFieldValue}
          fieldName={fieldName}
          infoArr={infoArr}
          autoFocusRef={autoFocusRef}
          inputAutoFocus={inputAutoFocus}
          message={message}
        />
      </MediaQuery>
    </React.Fragment>
  );
};

export default PhonePrefixTextField;
