/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from "react";
import {
  TextField,
  Grid,
  Button,
  CircularProgress,
  Select,
  MenuItem
} from "@material-ui/core";
import ConfirmationModal from "pages/flight/ConfirmationModal";
import PhonePrefix from "components/inputs/PhonePrefix";
import {
  SLEEP_SINGLE_EMPLOYEE_OPTION,
  SLEEP_FAMILY_FRIEND_OPTION,
  SLEEP_FRIEND_BNB_OPTION
} from "../../../config/constants";
import SelectBoxIcon from "@material-ui/icons/ExpandMore";
import MonthOptionComponent from "../../../components/inputs/MonthOptionComponent";
import DayOptionComponent from "../../../components/inputs/DayOptionComponent";
import YearOptionComponent from "../../../components/inputs/YearOptionComponent";

const styles = { marginTop: 30 };

const SleepCustomerInfoWeb = ({
  handleOpenClose,
  handleChange,
  errors,
  touched,
  values,
  showConfirmation,
  setFieldValue,
  handleBlur,
  handleSubmit,
  handleValidDate,
  errMsg,
  isLoading,
  familyValue,
  roomArr,
  isMultiple
}) => {
  const [familyState, setFamilyState] = useState(familyValue);
  const [maxDays, setMaxDays] = useState(31);
  const handleMonthChange = event => {
    const selectedMonth = event.target.value;
    let newMaxDays;
    if (selectedMonth === "02") {
      // February
      newMaxDays = 28; // default to 28 days
      if (values.dobYear && values.dobYear % 4 === 0) {
        // Leap year
        newMaxDays = 29;
      }
    } else if (["04", "06", "09", "11"].includes(selectedMonth)) {
      // April, June, September, November
      newMaxDays = 30;
    } else {
      newMaxDays = 31;
    }
    setMaxDays(newMaxDays); // update state with new value
  };

  return (
    <React.Fragment>
      <form
        style={{
          //position: "fixed", // prevent more space at bottom (can delete this after add more fields)
          overflow: "auto", // just open this after add more fields
          padding: "0",
          margin: "0",
          top: "0px", // after open overflow: "auto", set to "0px" again
          left: "0",
          width: "100%",
          height: "100%",
          minHeight: "100vh",
          backgroundColor: "#273142"
        }}
      >
        <Grid container className="customerContainer sleep-customerinfo">
          <Grid item md={2}>
            &nbsp;
          </Grid>

          <Grid item xs={10} sm={10}>
            <Select
              onChange={e => {
                setFamilyState(e.target.value);
                e.target.value === SLEEP_FAMILY_FRIEND_OPTION
                  ? setFieldValue("family_friend", 1)
                  : setFieldValue("family_friend", 0);
              }}
              IconComponent={SelectBoxIcon}
              className="familySelect readonly"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
              value={familyState}
              disabled={true}
            >
              <MenuItem value={SLEEP_SINGLE_EMPLOYEE_OPTION}>
                {SLEEP_SINGLE_EMPLOYEE_OPTION}
              </MenuItem>
              <MenuItem value={SLEEP_FAMILY_FRIEND_OPTION}>
                {SLEEP_FAMILY_FRIEND_OPTION}
              </MenuItem>
              <MenuItem value={SLEEP_FRIEND_BNB_OPTION}>
                {SLEEP_FRIEND_BNB_OPTION}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item md={2}>
            &nbsp;
          </Grid>

          <Grid item xs={8} sm={8}>
            <span className="title" style={{ color: "#ffffff" }}>
              {values.family_friend === 0
                ? "Personal Information"
                : "Starbase Host"}
            </span>
          </Grid>

          <Grid item md={2}>
            &nbsp;
          </Grid>

          <Grid item md={2}>
            &nbsp;
          </Grid>
          {roomArr.length === 0 && (
            <>
              <Grid item xs={8} sm={8}>
                <div
                  style={{
                    background: "#ffeaac",
                    textAlign: "center",
                    padding: "6px 0px",
                    marginTop: "6px",
                    borderRadius: "4px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    border: "1px solid rgb(255, 204, 0)"
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      fontSize: "32px",
                      lineHeight: "29px",
                      width: "32px",
                      height: "32px",
                      padding: "0px 20px 0px 0px",
                      color: "rgb(128, 0, 0)"
                    }}
                  >
                    ⚠
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      color: "rgb(0, 0, 0)",
                      float: "none",
                      lineHeight: "32px",
                      margin: "0px 10px 0px 0px",
                      textAlign: "initial"
                    }}
                  >
                    <div
                      style={{
                        display: "block",
                        fontSize: "12px",
                        lineHeight: "18px"
                      }}
                    >
                      Starbase is currently fully occupied. Join the waitlist
                      for a chance to stay. <br />
                      But please note that{" "}
                      <b>we cannot guarantee availability.</b>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item md={2}>
                &nbsp;
              </Grid>

              <Grid item md={2}>
                &nbsp;
              </Grid>
            </>
          )}
          <Grid item md={8}>
            <Grid container className="contactInfo">
              <Grid
                item
                md={6}
                className={
                  errors.firstName && touched.firstName
                    ? "searchErrMessage "
                    : ""
                }
              >
                <span className="fieldTitle">First Name</span>
                <span className="required">*</span>
                <TextField
                  name="firstName"
                  value={values.firstName}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                />
                {errors.firstName && touched.firstName && (
                  <span className="errMessage">{errors.firstName}</span>
                )}
              </Grid>
              <Grid
                item
                md={6}
                style={{ paddingLeft: "15px" }}
                className={
                  errors.lastName && touched.lastName ? "searchErrMessage " : ""
                }
              >
                <span className="fieldTitle">Last Name</span>
                <span className="required">*</span>
                <TextField
                  name="lastName"
                  value={values.lastName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.lastName && touched.lastName && (
                  <span className="errMessage">{errors.lastName}</span>
                )}
              </Grid>
              <Grid item sm={12} className="p-bottom-15"></Grid>
              <Grid item sm={6}>
                <span className="fieldTitle">Mobile Number</span>
                <span className="required">*</span>
                <Grid container>
                  <Grid item sm={4}>
                    <PhonePrefix
                      name="mobilePrefix"
                      value={values.mobilePrefix}
                      prefixValue={values.mobilePrefix}
                      handlePhonePrefix={value =>
                        setFieldValue("mobilePrefix", value)
                      }
                      handlePhoneFlag={value =>
                        setFieldValue("mobileCountryFlag", value)
                      }
                      mobileCountryFlag={values.mobileCountryFlag}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={8}
                    className={
                      errors.mobileNo && touched.mobileNo
                        ? "searchErrMessage "
                        : ""
                    }
                  >
                    <TextField
                      name="mobileNo"
                      value={values.mobileNo}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.mobileNo && touched.mobileNo && (
                      <span className="errMessage">{errors.mobileNo}</span>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={6}
                style={{ paddingLeft: "15px" }}
                className={
                  errors.email && touched.email ? "searchErrMessage " : ""
                }
              >
                <span className="fieldTitle">Email</span>
                <span className="required">*</span>
                <TextField
                  name="email"
                  value={values.email}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    readOnly: true
                  }}
                />
                {errors.email && touched.email && (
                  <span className="errMessage">{errors.email}</span>
                )}
                <br />
                <span className="fieldDescripiton">e.g. email@example.com</span>
              </Grid>
              <Grid item sm={12} className="p-bottom-15"></Grid>
              {values.family_friend === 0 && (
                <Grid
                  item
                  sm={6}
                  className={
                    errors.avgWork && touched.avgWork ? "searchErrMessage " : ""
                  }
                >
                  <span className="fieldTitle">
                    What is your average shift worked?
                  </span>
                  <span className="required">*</span>
                  <TextField
                    name="avgWork"
                    type="text"
                    value={values.avgWork}
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.avgWork && touched.avgWork && (
                    <span className="errMessage">{errors.avgWork}</span>
                  )}
                </Grid>
              )}

              {isMultiple && (
                <Grid item sm={6} style={{ paddingLeft: "15px" }}>
                  <span className="fieldTitle">CC</span>
                  <TextField
                    name="cc_email"
                    value={values.cc_email}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <br />
                  <span className="fieldDescripiton">
                    updates will also be send to this email address
                  </span>
                </Grid>
              )}

              {values.family_friend === 0 && (
                <>
                  {familyValue === SLEEP_SINGLE_EMPLOYEE_OPTION && (
                    <Grid item sm={6} style={{ paddingLeft: "15px" }}>
                      {/* <span className="fieldTitle">Housing preference</span>
                      <select
                        className="selectBox housing_preference"
                        name="housing_preference"
                        value={values.housing_preference}
                        onChange={handleChange}
                      >
                        {roomArr && roomArr.length === 0 && (
                          <option value="waitlist">to be waitlisted</option>
                        )}
                        {roomArr && roomArr.length > 0 && (
                          <option value="no_preference">no preference</option>
                        )}
                        {roomArr &&
                          roomArr.length > 0 &&
                          roomArr.map((val, index) => {
                            return (
                              <option key={index} value={val.room_id}>
                                {val.alias}
                              </option>
                            );
                          })}
                      </select> */}
                    </Grid>
                  )}
                  {familyValue === SLEEP_FRIEND_BNB_OPTION && (
                    <Grid
                      item
                      sm={6}
                      style={{ paddingLeft: isMultiple ? "0px" : "15px" }}
                      className={
                        errors.host_spacex_email && touched.host_spacex_email
                          ? "searchErrMessage "
                          : ""
                      }
                    >
                      <span className="fieldTitle">Host spacex.com email</span>
                      <span className="required">*</span>
                      <TextField
                        name="host_spacex_email"
                        type="text"
                        value={values.host_spacex_email}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.host_spacex_email &&
                        touched.host_spacex_email && (
                          <span className="errMessage">
                            {errors.host_spacex_email}
                          </span>
                        )}
                    </Grid>
                  )}
                  <Grid item sm={12} className="p-bottom-15"></Grid>
                  <Grid item sm={12}>
                    <span className="fieldTitle">Notes for Starbase team</span>
                    <TextField
                      name="requests"
                      type="text"
                      placeholder="roommate or location preferences, group information, etc"
                      value={values.requests}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </>
              )}

              {/* <Grid
                item
                sm={6}
                style={{ paddingLeft: "15px" }}
                className={
                  errors.position && touched.position ? "searchErrMessage " : ""
                }
              >
                <span className="fieldTitle">What is your position?</span>
                <span className="required">*</span>
                <TextField
                  name="position"
                  type="text"
                  value={values.position}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.position && touched.position && (
                  <span className="errMessage">{errors.position}</span>
                )}
              </Grid> */}
            </Grid>

            {values.family_friend === 1 && (
              <>
                <h4 className="familyTitle">Traveler to Starbase</h4>
                <Grid container className="familyFields">
                  <Grid
                    item
                    sm={6}
                    className={
                      errors.family_first_name && touched.family_first_name
                        ? "searchErrMessage"
                        : ""
                    }
                  >
                    <span className="fieldTitle">
                      First Name
                      <span className="required">*</span>
                    </span>
                    <TextField
                      name="family_first_name"
                      type="text"
                      value={values.family_first_name}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Family or Friend name*"
                    />
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    className={
                      errors.family_last_name && touched.family_last_name
                        ? "searchErrMessage"
                        : ""
                    }
                  >
                    <span className="fieldTitle">
                      Last Name
                      <span className="required">*</span>
                    </span>
                    <TextField
                      name="family_last_name"
                      type="text"
                      value={values.family_last_name}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Family or Friend name*"
                    />
                  </Grid>
                  <Grid item sm={6}></Grid>
                  {/* <Grid
                    item
                    sm={6}
                    style={{ paddingLeft: "10px" }}
                    className={
                      errors.family_relationship && touched.family_relationship
                        ? "searchErrMessage"
                        : ""
                    }
                  >
                    <span className="fieldTitle">
                      Relationship<span className="required">*</span>
                    </span>
                    <TextField
                      name="family_relationship"
                      type="text"
                      value={values.family_relationship}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Relationship*"
                    />
                  </Grid> */}
                  <Grid item sm={12} className="p-bottom-15"></Grid>
                  <Grid
                    item
                    sm={6}
                    className={
                      errors.family_phone && touched.family_phone
                        ? "searchErrMessage"
                        : ""
                    }
                  >
                    <span className="fieldTitle">
                      Phone number<span className="required">*</span>
                    </span>
                    <Grid container>
                      <Grid item sm={3}>
                        <PhonePrefix
                          name="family_phonePrefix"
                          value={values.family_phonePrefix}
                          prefixValue={values.family_phonePrefix}
                          handlePhonePrefix={value =>
                            setFieldValue("family_phonePrefix", value)
                          }
                          handlePhoneFlag={value =>
                            setFieldValue("familyMobileCountryFlag", value)
                          }
                          mobileCountryFlag={values.familyMobileCountryFlag}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={9}
                        style={{ paddingLeft: "10px" }}
                        className={
                          errors.family_phone && touched.family_phone
                            ? "searchErrMessage "
                            : ""
                        }
                      >
                        <TextField
                          name="family_phone"
                          value={values.family_phone}
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    style={{ paddingLeft: "10px" }}
                    className={
                      errors.family_email && touched.family_email
                        ? "searchErrMessage"
                        : ""
                    }
                  >
                    <span className="fieldTitle">
                      Email
                      <span className="required">*</span>
                    </span>
                    <TextField
                      name="family_email"
                      type="text"
                      value={values.family_email}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Family or Friend email*"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    {/* <span className="fieldTitle">Housing preference</span>
                    <select
                      className="selectBox housing_preference"
                      name="housing_preference"
                      value={values.housing_preference}
                      onChange={handleChange}
                    >
                      {roomArr && roomArr.length === 0 && (
                        <option value="waitlist">to be waitlisted</option>
                      )}
                      {roomArr && roomArr.length > 0 && (
                        <option value="no_preference">no preference</option>
                      )}
                      {roomArr &&
                        roomArr.length > 0 &&
                        roomArr.map((val, index) => {
                          return (
                            <option key={index} value={val.room_id}>
                              {val.alias}
                            </option>
                          );
                        })}
                    </select> */}
                  </Grid>
                  <Grid item sm={6} className="p-left-15">
                    <span className="fieldTitle">Required Information</span>
                    <Grid container>
                      <Grid
                        item
                        sm={5}
                        className="family-info-cell-label"
                        style={{ margin: "auto" }}
                      >
                        Date of Birth<span className="required">*</span>
                      </Grid>
                      <Grid
                        item
                        sm={7}
                        className={`charter-info-cell-input ${
                          (errors.dobDay && touched.dobDay) ||
                          (errors.dobMonth && touched.dobMonth) ||
                          (errors.dobYear && touched.dobYear)
                            ? "searchErrMessage"
                            : ""
                        }`}
                      >
                        {/* <SingleDatePicker
                          id="dob"
                          date={values.dob === "" ? null : values.dob}
                          placeholder="MM/DD/YYYY"
                          focused={dateFocus}
                          onFocusChange={() => setDateFocus(true)}
                          onDateChange={date =>
                            setFieldValue("dob", moment(date))
                          }
                          numberOfMonths={1}
                          navPrev={
                            <div className="arrow-left" id="prevMonth"></div>
                          }
                          navNext={
                            <div className="arrow-right" id="nextMonth"></div>
                          }
                          displayFormat="MM/DD/YYYY"
                          hideKeyboardShortcutsPanel={true}
                          keepOpenOnDateSelect={false}
                          onClose={() => setDateFocus(false)}
                          daySize={30}
                          transitionDuration={0}
                          isOutsideRange={() => false}
                          readOnly
                          renderMonthElement={RenderMonthElement}
                        /> */}
                        <Grid container>
                          <Grid item sm={5}>
                            <select
                              name="dobMonth"
                              className={
                                values.dobMonth === "00"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              required
                              value={values.dobMonth}
                              onChange={e => {
                                setFieldValue("dobMonth", e.target.value);
                                handleMonthChange(e);
                              }}
                            >
                              <MonthOptionComponent />
                            </select>
                          </Grid>
                          <Grid item sm={3}>
                            <select
                              name="dobDay"
                              className={
                                values.dobDay === "00"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              value={values.dobDay}
                              onChange={e =>
                                setFieldValue("dobDay", e.target.value)
                              }
                              required
                            >
                              <DayOptionComponent dayOption={maxDays} />
                            </select>
                          </Grid>
                          <Grid item sm={4}>
                            <select
                              className={
                                values.dobYear === "0000"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              name="dobYear"
                              value={values.dobYear}
                              onChange={e => {
                                setFieldValue("dobYear", e.target.value);
                              }}
                              onBlur={handleBlur}
                            >
                              <YearOptionComponent />
                            </select>
                          </Grid>
                          {((errors.dobDay && touched.dobDay) ||
                            (errors.dobMonth && touched.dobMonth) ||
                            (errors.dobYear && touched.dobYear)) && (
                            <span className="errMessage">{errors.dobYear}</span>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12}>
                    <span className="fieldTitle">Notes for Starbase team</span>
                    <TextField
                      name="requests"
                      type="text"
                      placeholder="roommate or location preferences, group information, etc"
                      value={values.requests}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid item sm={12} style={styles}>
              <ConfirmationModal
                showConfirmation={showConfirmation}
                handleClose={handleOpenClose}
                isLoading={isLoading}
                handleContinue={handleSubmit}
              />

              {!handleValidDate && (
                <span
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    display: "inline-block",
                    width: "100%",
                    textAlign: "right"
                  }}
                >
                  Please check your checkin and checkout date.
                </span>
              )}
              {errMsg !== "" && (
                <span
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    display: "inline-block",
                    width: "100%",
                    textAlign: "right"
                  }}
                >
                  {errMsg}
                </span>
              )}
              <Button
                id="fl-customer-btn-continue"
                className="btnContinue pull-right"
                onClick={handleSubmit}
                disabled={
                  !handleValidDate ||
                  (errMsg !== "" ? true : false) ||
                  (isLoading ? true : false)
                }
              >
                {roomArr.length === 0 ? "Join Waitlist" : "Confirm Booking"}
                {!handleValidDate ||
                  (errMsg !== "" ? <CircularProgress /> : "") ||
                  (isLoading ? <CircularProgress /> : "")}
              </Button>
            </Grid>
          </Grid>

          <Grid item md={2}>
            &nbsp;
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default SleepCustomerInfoWeb;
