import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { Button } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
// import { SchemaMetaFieldDef } from "graphql/type";

const PhonePrefixPopupMobile = props => {
  let {
    isOpen,
    filterList,
    handleClick,
    name,
    handleChange,
    handleCloseMobile,
    type,
    handleCountryOfPassport,
    setFieldValue,
    fieldName,
    autoFocusRef,
    inputAutoFocus
  } = props;

  useEffect(() => {
    if (isOpen === true) {
      setTimeout(function() {
        // document.getElementById(name).focus();
        inputAutoFocus();
      }, 500);
    }
  }, [inputAutoFocus, isOpen]);

  function handleTabEnter(props, targetValue) {
    if (type === "country") {
      filterList.length !== 0 &&
        filterList[0].CountryName &&
        handleClick(
          type === "country"
            ? filterList[0].CountryId + "#" + filterList[0].CountryName
            : "+" + filterList[0].CountryCode
        );

      handleCountryOfPassport(filterList[0].CountryId);
      setFieldValue(name, filterList[0].CountryId);
    } else {
      filterList.length !== 0 &&
        filterList[0].CountryName &&
        handleClick(
          type === "country"
            ? filterList[0].CountryId + "#" + filterList[0].CountryName
            : "+" + filterList[0].CountryCode
        );
      setFieldValue(
        name,
        filterList && filterList.length > 0 ? filterList[0].CountryCode : ""
      );
    }
  }

  function handleCursorPosition(currentPosition) {
    switch (currentPosition) {
      case "mobilePrefix":
        document.getElementById("mobile_number").focus();
        break;
      case fieldName + ".mobilePrefix":
        document
          .querySelector("input[name='" + fieldName + ".mobileNo']")
          .focus();
        break;
      case fieldName + ".countryOfPassport":
        document
          .querySelector("select[name='" + fieldName + ".expDay']")
          .focus();
        break;
      default:
        console.log("default");
    }
  }
  return (
    <React.Fragment>
      <Slide direction="up" in={isOpen} className="slideContainter">
        <Paper>
          <div className="popupSearch">
            <Button className="closeAiport">
              <CloseIcon onClick={handleCloseMobile} />
            </Button>
            <div className="searchTitle">
              {type === "country" ? "Country of Passport" : "Country Code"}
            </div>
            <TextField
              placeholder="Enter country by name (e.g. Myanmar)"
              className={
                type === "country" ? "countrySearch" : "countryPrefixSearch"
              }
              id={name}
              onChange={event => {
                handleChange(event.target.value);
              }}
              onBlur={e => {
                isOpen === false && handleCursorPosition(e.target.id);
              }}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  handleTabEnter(props, e.target.value);
                  handleCursorPosition(e.target.id);
                }
              }}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon className="airportSearchIcon" />
                  </InputAdornment>
                ),
                disableUnderline: true
              }}
              ref={autoFocusRef}
            />
          </div>
          <Grid container>
            {filterList.length === 0 && (
              <Grid item={true} xs={12} className="noResult">
                I'm sorry, country could not be found!
              </Grid>
            )}
            {filterList.length > 0 &&
              filterList.map((country, key) => {
                return (
                  <Grid
                    item={true}
                    xs={12}
                    className="airportList prefixList"
                    key={key}
                    onClick={() => {
                      type === "country" &&
                        handleCountryOfPassport(country.CountryId);
                      handleClick(
                        type === "country"
                          ? country.CountryId + "#" + country.CountryName
                          : "+" + country.CountryCode
                      );
                      if (type !== "country") {
                        setFieldValue(name, country.CountryCode);
                      }
                      if (type === "country") {
                        setFieldValue(name, country.CountryId);
                      }
                    }}
                  >
                    <div className="mainPrefixContainer">
                      <div
                        className={
                          type === "country"
                            ? "fullPrefixContainer"
                            : "leftPrefixContainer"
                        }
                      >
                        {type === "country" && country.CountryName}
                        {type !== "country" &&
                          country.CountryName +
                            " (" +
                            (type !== "country" && country.ISOCode) +
                            ")"}
                      </div>
                      {type !== "country" && (
                        <div className="rightPrefixContainer">
                          {"+" + country.CountryCode}
                        </div>
                      )}
                    </div>
                  </Grid>
                );
              })}
          </Grid>
        </Paper>
      </Slide>
    </React.Fragment>
  );
};

export default PhonePrefixPopupMobile;
