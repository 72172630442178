import React, { useEffect, useState } from "react";
import "assets/scss/SleepDoorCode.scss";
import Axios from "axios";

const CarCancel = ({ history, match }) => {
  const CHECK_RESERVATION = "/public/reservation/validVehicleBooking";
  const [status, setStatus] = useState(false);
  //const [cancel, setCancel] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const cancelBooking = () => {
    setCancelLoading(true);
    Axios.post("/public/reservation/cancelVehicleBooking", {
      bookingCode: match.params.bookingCode
    })
      .then(res => res.data && history.push("/car/cancel-confirmation"))
      .catch(err => {
        //setCancel("not found");
        console.log(err);
      })
      .then(res => setCancelLoading(false));
  };

  useEffect(() => {
    Axios.post(CHECK_RESERVATION, {
      bookingCode: match.params.bookingCode
    })
      .then(res => res.data && setStatus(res.data.status))
      .catch(err => setStatus("not found"));
  }, [match.params.bookingCode]);

  if (!status) {
    return null;
  }

  return (
    <div className="SleepDoorCode" id="FlyLandingPage">
      <div
        className="wrapper"
        style={{
          maxWidth: "600px",
          marginTop: "0",
          alignSelf: "center",
          minHeight: "100vh"
        }}
      >
        <div className="cancel-header-region">
          <span onClick={() => history.push("/")}>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back to
            Main Menu
          </span>
        </div>
        {status === "not found" ? (
          <h2 className="title">Reservation Not Found</h2>
        ) : (
          <>
            <h2 className="title" style={{ marginTop: "10px" }}>
              Cancel your request
            </h2>
            <p className="booking-id">
              If you would like to cancel your car request, click the button
              below.
            </p>
            {/* <p className="booking-id" style={{ marginBottom: "5px" }}>
          This is only to confirm receipt of your request on the Starship
          charter flight.
        </p>
        <ul style={{ marginTop: "5px" }}>
          <li>
            <p className="booking-id" style={{ margin: "8px 0" }}>
              <span>{`{name}`}</span> (name must match ID)
            </p>
          </li>
          <li>
            <p className="booking-id" style={{ margin: "8px 0" }}>
              Depart: <span>{`{depart_datetime}`}</span> LAX Signature, CA
            </p>
          </li>
          <li>
            <p className="booking-id" style={{ margin: "8px 0" }}>
              Arrive: {`{arrive_datetime}`} LAX Signature, CA
            </p>
          </li>
        </ul>
        <p className="booking-id" style={{ marginTop: "30px" }}>
          You will receive a final confirmation of your flight within 24 hours
          of your flight departure, as flights are typically limited in space
          and baggage weight.
        </p>
        <p className="booking-id" style={{ marginTop: "30px" }}>
          To cancel your flight request:
        </p> */}
            <button
              className="theme-btn"
              style={{ marginLeft: "0", marginBottom: "30px" }}
              onClick={() => cancelBooking()}
              disabled={cancelLoading}
            >
              Cancel my car request
            </button>
            <br />
          </>
        )}
      </div>
    </div>
  );
};

export default CarCancel;
