import React, { Component } from "react";
import "App.css";
import "assets/scss/Home.scss";
import "assets/scss/HomeMobile.scss";
import "assets/scss/FlightSearch.scss";
import "assets/scss/CustomerInfo.scss";
import "assets/scss/BalloonSearch.scss";
import "assets/scss/FlightConfirmation.scss";
import "assets/scss/PaymentPage.scss";
import "assets/scss/SpacexSleep.scss";
import "assets/scss/Spacex.scss";
import "assets/scss/SpacexInfo.scss";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router
} from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Loadable from "react-loadable";
import SAML from "pages/spacex/SAML";
import SleepPersonalInfo from "pages/spacex/sleep/SleepPersonalInfo";
import FlyPersonalInfo from "pages/spacex/fly/FlyPersonalInfo";
import SleepPage from "pages/spacex/SleepPage";
import FlyResult from "pages/spacex/fly/FlyResult";
import SleepDoorCode from "pages/spacex/sleep/SleepDoorCode";
import SleepDoorCode2 from "pages/spacex/sleep/SleepDoorCode2";
import SleepDoorCode3 from "pages/spacex/sleep/SleepDoorCode3";
import SleepConfirmation from "pages/spacex/sleep/SleepConfirmation";
import FlyConfirmation from "pages/spacex/fly/FlyConfirmation";
import CarConfirmation from "pages/spacex/cars/CarConfirmation";
import SpacexLogin from "pages/spacex/SpacexLogin";
import Logout from "pages/Logout";
import SpacexHome from "pages/spacex/SpacexHome";
import SignaturePage from "pages/spacex/SignaturePage";
import FlyLandingPage from "pages/spacex/fly/FlyLandingPage";
import FlyCancellation from "pages/spacex/fly/FlyCancellation";
import CarCancel from "pages/spacex/cars/CarCancel";
import CarCancellation from "pages/spacex/cars/CarCancellation";
import { SPACEX_LOGIN_EMAIL } from "config/constants";
import SleepCheckIn from "./pages/spacex/sleep/SleepCheckIn";
import SleepCheckinConfirmation from "./pages/spacex/sleep/SleepCheckinConfirmation";
import SleepCheckinCancellation from "./pages/spacex/sleep/SleepCheckinCancellation";
import SleepCancel from "./pages/spacex/sleep/SleepCancel";
import SleepCancelConfirmation from "./pages/spacex/sleep/SleepCancelConfirmation";
import Information from "pages/spacex/Information";
import CarPersonalInfo from "pages/spacex/cars/CarPersonalInfo";
import CarHome from "pages/spacex/cars/CarHome";
import HovercraftResult from "pages/spacex/hovercraft/HovercraftResult";
import HovercraftPersonalInfo from "pages/spacex/hovercraft/HovercraftPersonalInfo";
import HovercraftCancellation from "pages/spacex/hovercraft/HovercraftCancellation";
import HovercraftCancel from "./pages/spacex/hovercraft/HovercraftCancel";
import HovercraftConfirmation from "pages/spacex/hovercraft/HovercraftConfirmation";
import SleepCheckout from "./pages/spacex/sleep/SleepCheckout";
import SleepCheckoutConfirmation from "./pages/spacex/sleep/SleepCheckoutConfirmation";

const Page404 = Loadable({
  loader: () => import("pages/Page404"),
  loading() {
    return <div></div>;
  }
});

const ExtendPage = Loadable({
  loader: () => import("pages/spacex/ExtendPage"),
  loading() {
    return <div></div>;
  }
});
const ExtendConfirmation = Loadable({
  loader: () => import("pages/spacex/ExtendConfirmation"),
  loading() {
    return <div></div>;
  }
});

const theme = createMuiTheme({
  typography: {
    fontFamily: "Roboto, sans-serif"
  }
});

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        sessionStorage.getItem(SPACEX_LOGIN_EMAIL) &&
        sessionStorage.getItem("access_token") ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/info" component={Information} />
            <Route exact path="/login" component={SpacexLogin} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/signature" component={SignaturePage} />
            <Route
              exact
              path="/sleep/extend/:bookingId/confirmation"
              component={ExtendConfirmation}
            />
            <Route exact path="/sleep/extend/" component={ExtendPage} />
            <PrivateRoute exact path="/car" component={CarHome} />
            <PrivateRoute
              exact
              path="/car/personal-information/:startdDate/:vehicleId/:unitId"
              component={CarPersonalInfo}
            />
            <Route exact path="/car/confirmation" component={CarConfirmation} />
            <Route
              exact
              path="/sleep/extend/:bookingId"
              component={ExtendPage}
            />
            {/* <Route exact path="/sleep/doorcode" component={SleepDoorCode3} />
            <Route
              exact
              path="/sleep/doorcode/:bookingCode"
              component={SleepDoorCode3}
            /> */}
            <Route
              exact
              path="/fly/cancel/:bookingCode"
              component={FlyLandingPage}
            />
            <Route
              exact
              path="/fly/cancel-confirmation"
              component={FlyCancellation}
            />
            <PrivateRoute
              exact
              path="/sleep/checkin/example"
              component={SleepDoorCode}
            />
            <PrivateRoute
              exact
              path="/sleep/checkin/example2"
              component={SleepDoorCode2}
            />
            <PrivateRoute exact path="/sleep" component={SleepPage} />
            <PrivateRoute
              exact
              path="/sleep/confirmation"
              component={SleepConfirmation}
            />
            <Route
              exact
              path="/sleep/cancel/:bookingCode"
              component={SleepCancel}
            />
            <Route
              exact
              path="/sleep/cancel-confirmation"
              component={SleepCancelConfirmation}
            />
            <Route
              exact
              path="/car/cancel/:bookingCode"
              component={CarCancel}
            />
            <Route
              exact
              path="/car/cancel-confirmation"
              component={CarCancellation}
            />
            <Route exact path="/saml" component={SAML} />
            <PrivateRoute
              exact
              path="/drive/confirmation"
              component={SleepConfirmation}
            />
            <PrivateRoute
              exact
              path="/fly/confirmation/"
              component={FlyConfirmation}
            />
            <PrivateRoute exact path="/" component={SpacexHome} />
            <PrivateRoute exact path="/fly" component={FlyResult} />
            <PrivateRoute
              exact
              path="/fly/personal-information/:flightId/:isBrownsville"
              component={FlyPersonalInfo}
            />
            <PrivateRoute
              exact
              path="/sleep/personal-information/:checkinDate/:checkoutDate/:roomId"
              component={SleepPersonalInfo}
            />
            <PrivateRoute
              exact
              path="/sleep/personal-information/:checkinDate/:checkoutDate"
              component={SleepPersonalInfo}
            />
            <Route
              exact
              path="/doorcode/:bookingCode"
              component={SleepDoorCode3}
            />
            <Route
              exact
              path="/sleep/checkin/:bookingCode"
              component={SleepCheckIn}
            />
            <Route
              exact
              path="/sleep/checkin-confirmation"
              component={SleepCheckinConfirmation}
            />
            <Route
              exact
              path="/sleep/checkin-cancel"
              component={SleepCheckinCancellation}
            />
            <Route
              exact
              path="/sleep/checkout/:bookingCode"
              component={SleepCheckout}
            />
            <Route
              exact
              path="/sleep/checkout-confirmation"
              component={SleepCheckoutConfirmation}
            />
            <PrivateRoute
              exact
              path="/hovercraft"
              component={HovercraftResult}
            />
            <PrivateRoute
              exact
              path="/hovercraft/personal-information/:hovercraftId/:isBrownsville"
              component={HovercraftPersonalInfo}
            />
            <PrivateRoute
              exact
              path="/hovercraft/confirmation"
              component={HovercraftConfirmation}
            />
            <Route
              exact
              path="/hovercraft/cancel/:bookingCode"
              component={HovercraftCancel}
            />
            <PrivateRoute
              exact
              path="/hovercraft/cancel-confirmation"
              component={HovercraftCancellation}
            />

            <Route component={Page404} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
