import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { Grid, Button, Switch } from "@material-ui/core";
import parse from "html-react-parser";
import axios from "axios";
import LoadingComponent from "components/LoadingComponent";
import MediaQuery from "react-responsive";
import SpacexWrapper from "../SpacexWrapper";
import { SingleDatePicker } from "react-dates";
import { filter, includes, min } from "lodash";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import vector from "assets/images/vector.svg";

const HovercraftBookButton = ({ hovercraft, history }) => {
  const isBrownsVille = hovercraft => {
    return (hovercraft.to_text && hovercraft.to_text.includes("Brownsville")) ||
      (hovercraft.stops && hovercraft.stops.includes("BRO"))
      ? "1"
      : "0";
  };

  const isFull = hovercraft => ["unavailable"].includes(hovercraft.seats_text);

  const handleIsAllow = () => {
    history.push({
      pathname: `/hovercraft/personal-information/${
        hovercraft.id
      }/${isBrownsVille(hovercraft)}`,
      state: { isNda: hovercraft.nda, isCharter: hovercraft.charter }
    });
  };

  return (
    <>
      <Button
        className={`btnChoose  ${
          isFull(hovercraft)
            ? "fl-result-btn-disable"
            : "fl-result-btn-chooseDept"
        }`}
        disabled={isFull(hovercraft)}
        onClick={() => !isFull(hovercraft) && handleIsAllow()}
      >
        {isFull(hovercraft) ? "Sold Out" : "Choose"}
      </Button>
      {/* </Link> */}
      {/* <div className="availability">{hovercraft.seats_text}</div> */}
    </>
  );
};

const ConnectingHovercraftText = ({ hovercraft }) => {
  return (
    <>
      {hovercraft.route_type === "connecting" && (
        <>
          <br />
          <span className="connectingFlight">
            <b>
              {hovercraft.stops.length} connection in{" "}
              {hovercraft.stops.join(", ")}
            </b>
          </span>
        </>
      )}
    </>
  );
};

const HovercraftResult = props => {
  const [hovercraftArr, setHovercraftArr] = useState([]);
  const [filterArr, setFilterArr] = useState([]);
  const [errCount, setErrCount] = useState(0);
  const [isGetData, setIsGetData] = useState(0);
  const [dateVal, setDateVal] = useState(null);
  const [dateFocus, setDateFocus] = useState(false);
  const [isAllDate, setIsAllDate] = useState(true);
  const [flag, setFlag] = useState(false);

  const availableDates =
    hovercraftArr.length > 0 ? hovercraftArr.map(val => val.from_date) : [];
  const uniqueArray = availableDates.filter(function(item, pos) {
    return availableDates.indexOf(item) === pos;
  });
  const isBlocked = day => !uniqueArray.includes(day.format("YYYY-MM-DD"));

  const commonGetAvailableHovercraft = () => {
    let params = {
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment()
        .add(30, "days")
        .format("YYYY-MM-DD")
    };

    axios
      .post("/api/hovercraft/availability", params)
      .then(function(response) {
        response.data &&
          !response.data.error &&
          setHovercraftArr(response.data.hovercrafts);
        response.data &&
          !response.data.error &&
          setFilterArr(response.data.hovercrafts);
        response.data &&
          !response.data.error &&
          response.data.hovercrafts.length === 0 &&
          setIsGetData(1);

        response.data && response.data.error && setHovercraftArr([]);
        response.data && response.data.error && setFilterArr([]);
        response.data && response.data.error && setErrCount(1);
      })
      .catch(function(error) {
        console.log(error);
        setErrCount(1);
      });
  };

  const handleDateFilter = useCallback(
    (val, type, checked) => {
      if (type === "switch" && checked) {
        setFilterArr(hovercraftArr);
        return;
      }

      if (type === "switch" && !checked) {
        let hovercrafts = hovercraftArr;
        let arr = filter(hovercrafts, hovercraft =>
          includes(hovercraft.from_date, moment().format("YYYY-MM-DD"))
        );
        setDateVal(moment().format("ddd, MMM Do"));
        setFilterArr(arr);
        return;
      }

      let hovercrafts = hovercraftArr;
      let arr = filter(hovercrafts, hovercraft =>
        includes(hovercraft.from_date, val)
      );
      setFilterArr(arr);
    },
    [hovercraftArr]
  );

  useEffect(() => {
    if (!flag) {
      commonGetAvailableHovercraft();
      setFlag(true);
    }
  }, [flag]);

  return (
    <SpacexWrapper>
      <div className="resultCard flyresult" style={{ position: "initial" }}>
        <div className="innerflyresult">
          <Grid container className="date-region">
            <div className="date-inner hovercraft-date">
              <div className="allDatesDiv">
                <span className="showAllDates">Show All Dates</span>
                <Switch
                  checked={isAllDate}
                  onChange={e => {
                    setIsAllDate(e.target.checked);
                    handleDateFilter(
                      moment(dateVal, "ddd, MMM Do").format("YYYY-MM-DD"),
                      "switch",
                      e.target.checked
                    );
                  }}
                  name="allDates"
                />
              </div>
              {/* <span
                className={`leftArrow arrow${
                  uniqueArray.length === 0 ? true : isAllDate
                }`}
                onClick={() => {
                  const getDate = dateVal
                    ? moment(dateVal, "ddd, MMM Do")
                        .subtract(1, "days")
                        .format("YYYY-MM-DD")
                    : min(uniqueArray);
                  const isInclude = uniqueArray.includes(getDate);
                  isInclude &&
                    setDateVal(
                      moment(getDate, "YYYY-MM-DD").format("ddd, MMM Do")
                    );
                  isInclude && handleDateFilter(getDate, "datepicker");
                }}
              >
                <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
              </span> */}
              <SingleDatePicker
                id="hovercraftStartdate"
                date={dateVal ? moment(dateVal, "ddd, MMM Do") : null}
                placeholder="Search by date"
                focused={dateFocus}
                onFocusChange={() => setDateFocus(true)}
                onDateChange={date => {
                  setDateVal(date.format("ddd, MMM Do"));
                  handleDateFilter(date.format("YYYY-MM-DD"), "datepicker");
                }}
                numberOfMonths={1}
                navPrev={<div className="arrow-left" id="prevMonth"></div>}
                navNext={<div className="arrow-right" id="nextMonth"></div>}
                displayFormat="ddd, MMM Do"
                hideKeyboardShortcutsPanel={true}
                keepOpenOnDateSelect={true}
                onClose={() => setDateFocus(false)}
                showDefaultInputIcon
                inputIconPosition="after"
                daySize={30}
                transitionDuration={0}
                isDayBlocked={isBlocked}
                disabled={uniqueArray.length === 0 ? true : isAllDate}
                readOnly
              />
              {/* <span
                className={`rightArrow arrow${
                  uniqueArray.length === 0 ? true : isAllDate
                }`}
                onClick={() => {
                  const getDate = dateVal
                    ? moment(dateVal, "ddd, MMM Do")
                        .add(1, "days")
                        .format("YYYY-MM-DD")
                    : min(uniqueArray);
                  const isInclude = uniqueArray.includes(getDate);
                  isInclude &&
                    setDateVal(
                      moment(getDate, "YYYY-MM-DD").format("ddd, MMM Do")
                    );
                  isInclude && handleDateFilter(getDate, "datepicker");
                }}
              >
                <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
              </span> */}
            </div>
          </Grid>

          {/* Desktop or laptop For Result List*/}
          {/* original minDeviceWdith={1224} */}
          <MediaQuery minDeviceWidth={768}>
            <Grid container className="serachDetail flysearchDetail">
              {filterArr.length > 0 &&
                filterArr.map((hovercraft, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Grid container className="divDetail">
                        <Grid item sm={12} xs={12} className="flydetailBox">
                          <Grid container>
                            <Grid item sm={2} xs={7} className="flyinfoSection">
                              <span>
                                {hovercraft.from_date
                                  ? parse(
                                      moment(hovercraft.from_date, "YYYY-MM-DD")
                                        .format("MMM Do")
                                        .replace(
                                          /(\d)(st|nd|rd|th)/g,
                                          "$1<sup>$2</sup>"
                                        )
                                    )
                                  : ""}
                              </span>
                              <span>
                                {hovercraft.from_date
                                  ? moment(
                                      hovercraft.from_date,
                                      "YYYY-MM-DD"
                                    ).format("dddd")
                                  : ""}
                              </span>
                            </Grid>
                            <Grid
                              item
                              sm={3}
                              xs={12}
                              className="flyarrivalInfo"
                            >
                              <span className="infoType">Departs</span>
                              <h5>
                                {hovercraft.from_time
                                  ? moment(
                                      hovercraft.from_time,
                                      "HH:mm:ss"
                                    ).format("hh:mm A") +
                                    (hovercraft.from_timezone &&
                                    hovercraft.from_timezone.abbr
                                      ? hovercraft.from_timezone.abbr ===
                                        "unknown"
                                        ? ` UTC${hovercraft.from_timezone.offset}`
                                        : ` ${hovercraft.from_timezone.abbr.toUpperCase()}`
                                      : "")
                                  : ""}
                              </h5>
                              <span className="flightDestination">
                                <span className="firstPhrase">
                                  {hovercraft.from_text
                                    ? hovercraft.from_text
                                    : ""}
                                </span>
                              </span>
                              <ConnectingHovercraftText
                                hovercraft={hovercraft}
                              />
                              <span className="vectorSection">
                                <img src={vector} alt="" />
                              </span>
                            </Grid>
                            <Grid
                              item
                              sm={2}
                              xs={12}
                              className="flyarrivalInfo"
                            >
                              <span className="infoType">Arrives</span>
                              <h5>
                                {hovercraft.to_time
                                  ? moment(
                                      hovercraft.to_time,
                                      "HH:mm:ss"
                                    ).format("hh:mm A") +
                                    (hovercraft.to_timezone &&
                                    hovercraft.to_timezone.abbr
                                      ? hovercraft.to_timezone.abbr ===
                                        "unknown"
                                        ? ` UTC${hovercraft.to_timezone.offset}`
                                        : ` ${hovercraft.to_timezone.abbr.toUpperCase()}`
                                      : "")
                                  : ""}
                              </h5>
                              <span className="flightDestination">
                                <span className="firstPhrase">
                                  {hovercraft.to_text ? hovercraft.to_text : ""}
                                </span>
                              </span>
                            </Grid>
                            <Grid item sm={3} xs={1} className="iconSection">
                              <div></div>
                            </Grid>
                            <Grid
                              item
                              sm={2}
                              xs={12}
                              className="flightBookBtn hovercraftBtn"
                            >
                              <HovercraftBookButton
                                hovercraft={hovercraft}
                                history={props.history}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </Grid>
          </MediaQuery>

          {/* Tablet or Mobile For Result List*/}
          {/* maxWidth={1224} original */}
          <MediaQuery maxWidth={780}>
            <Grid container className="serachDetail flysearchDetail">
              {filterArr.length > 0 &&
                filterArr.map((hovercraft, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Grid container className="divDetail">
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          className="flydetailBox mobile"
                        >
                          <Grid container>
                            <Grid item sm={7} xs={7} className="flyinfoSection">
                              <span>
                                {hovercraft.from_date
                                  ? parse(
                                      moment(hovercraft.from_date, "YYYY-MM-DD")
                                        .format("dddd, MMM Do")
                                        .replace(
                                          /(\d)(st|nd|rd|th)/g,
                                          "$1<sup>$2</sup>"
                                        )
                                    )
                                  : ""}
                              </span>
                            </Grid>
                            <Grid item sm={5} xs={5} className="flyAvaSection">
                              <span className="availability">
                                {hovercraft.seats_text}
                              </span>
                            </Grid>
                            <Grid
                              item
                              sm={5}
                              xs={6}
                              className="flyarrivalInfo mobile"
                            >
                              <span className="infoType">Departs</span>
                              <h5>
                                {hovercraft.from_time
                                  ? moment(
                                      hovercraft.from_time,
                                      "HH:mm:ss"
                                    ).format("hh:mm A") +
                                    (hovercraft.from_timezone &&
                                    hovercraft.from_timezone.abbr
                                      ? hovercraft.from_timezone.abbr ===
                                        "unknown"
                                        ? ` UTC${hovercraft.from_timezone.offset}`
                                        : ` ${hovercraft.from_timezone.abbr.toUpperCase()}`
                                      : "")
                                  : ""}
                              </h5>
                              <span className="flightDestination">
                                <span className="firstPhrase">
                                  {hovercraft.from_text
                                    ? hovercraft.from_text
                                    : ""}
                                </span>
                              </span>
                              <ConnectingHovercraftText
                                hovercraft={hovercraft}
                              />
                              <span className="vectorSection">
                                <img src={vector} alt="" />
                              </span>
                            </Grid>
                            <Grid
                              item
                              sm={5}
                              xs={6}
                              className="flyarrivalInfo mobile"
                            >
                              <span className="infoType">Arrives</span>
                              <h5>
                                {hovercraft.to_time
                                  ? moment(
                                      hovercraft.to_time,
                                      "HH:mm:ss"
                                    ).format("hh:mm A") +
                                    (hovercraft.to_timezone &&
                                    hovercraft.to_timezone.abbr
                                      ? hovercraft.to_timezone.abbr ===
                                        "unknown"
                                        ? ` UTC${hovercraft.to_timezone.offset}`
                                        : ` ${hovercraft.to_timezone.abbr.toUpperCase()}`
                                      : "")
                                  : ""}
                              </h5>
                              <span className="flightDestination">
                                <span className="firstPhrase">
                                  {hovercraft.to_text ? hovercraft.to_text : ""}
                                </span>
                              </span>
                            </Grid>
                            <div className="dividedMobile">&nbsp;</div>
                            <Grid item sm={5} xs={8} className="iconSection">
                              <div></div>
                            </Grid>
                            <Grid item sm={7} xs={4} className="flightBookBtn">
                              <HovercraftBookButton
                                hovercraft={hovercraft}
                                history={props.history}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </Grid>
          </MediaQuery>

          {hovercraftArr.length === 0 && errCount === 0 && isGetData === 0 && (
            <Grid container className="serachDetail">
              <LoadingComponent />
            </Grid>
          )}

          {hovercraftArr.length === 0 && (errCount === 1 || isGetData === 1) && (
            <Grid container>
              <span className="spacex-noflyMsg" style={{ padding: "20px" }}>
                We are sorry. There are no upcoming hovercrafts.
              </span>
            </Grid>
          )}

          {hovercraftArr.length !== 0 &&
            filterArr.length === 0 &&
            errCount === 0 &&
            isGetData === 0 && (
              <Grid container>
                <span className="spacex-noflyMsg" style={{ padding: "20px" }}>
                  There are no hovercrafts on this date.
                </span>
              </Grid>
            )}
        </div>
      </div>
    </SpacexWrapper>
  );
};

export default HovercraftResult;
