import React, { useState, useRef } from "react";
import { Grid, RootRef } from "@material-ui/core";
import PhonePrefixTextField from "components/PhonePrefixTextField";

const PhonePrefix = ({
  countryFile,
  type,
  dateFocusIn,
  inputName,
  handleCountryOfPassport,
  handleUpdate,
  setFieldValue,
  fieldName,
  infoArr
}) => {
  const [country, setCountry] = useState(
    type === "country" ? "United States" : "+1"
  );
  const [countryOpen, setCountryOpen] = useState(false);
  const [countryEl, setCountryEl] = useState(null);
  const [countryFilter, setCountryFilter] = useState(countryFile);
  const [message] = useState("");

  const fromField = useRef(null);
  const textInput = useRef(null);

  const handleFocus = (name, other) => event => {
    setCountryOpen(true);
    setCountryEl(event.currentTarget);
  };

  const handleChange = name => {
    const inputValue = name.trim().toLowerCase();
    let filterCountries = countryFile.filter(
      country =>
        country.CountryName.toLowerCase().indexOf(inputValue) !== -1 && country
    );
    setCountryFilter(filterCountries);
    setCountry(name);
  };

  const handleClick = value => {
    setCountryOpen(false);
    setCountry(value);
  };

  const handleCloseMobile = () => {
    setCountryOpen(false);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={3}
        onFocus={dateFocusIn}
        className="phoneprefixGrid"
      >
        <div className="clickAwayDiv">
          <RootRef rootRef={fromField}>
            <PhonePrefixTextField
              name="country"
              value={country}
              handleFocus={handleFocus}
              handleChange={handleChange}
              isOpen={countryOpen}
              anchorEl={countryEl}
              filterList={countryFilter}
              handleClick={handleClick}
              className="editForm"
              handleCloseMobile={handleCloseMobile}
              type={type}
              inputName={inputName}
              handleCountryOfPassport={handleCountryOfPassport}
              handleUpdate={handleUpdate}
              setFieldValue={setFieldValue}
              fieldName={fieldName}
              infoArr={infoArr}
              autoFocusRef={textInput}
              inputAutoFocus={() =>
                textInput.current && textInput.current.focus()
              }
              message={message}
            />
          </RootRef>
        </div>
      </Grid>
    </>
  );
};

export default PhonePrefix;
