import React from "react";
import MediaQuery from "react-responsive";
import HovercraftCustomerInfoWeb from "pages/spacex/hovercraft/HovercraftCustomerInfoWeb";
import HovercraftCustomerInfoMobile from "pages/spacex/hovercraft/HovercraftCustomerInfoMobile";
import { withRouter } from "react-router";
import { withFormik } from "formik";
import HovercraftValidationSchema from "./HovercraftValidationSchema.js";
import { compose } from "recompose";
import { COUNTRIES } from "config/data/countries";
import { SPACEX_LOGIN_EMAIL } from "config/constants";

const MyForm = props => {
  const handleOpenClose = value => {
    props.setFieldValue("showConfirmation", value);
  };
  const handleCloseModal = () => {
    props.setFieldValue("showConfirmation", false);
  };
  const handleContactInfo = value => {
    let { setFieldValue, values } = props;
    if (value) {
      setFieldValue("adultInfo[0].title", values.gender);
      setFieldValue("adultInfo[0].firstName", values.firstName);
      setFieldValue("adultInfo[0].lastName", values.lastName);
      setFieldValue("adultInfo[0].mobileCountryFlag", values.mobileCountryFlag);
      setFieldValue("adultInfo[0].mobilePrefix", values.mobilePrefix);
      setFieldValue("adultInfo[0].mobileNo", values.mobileNo);
      setFieldValue("adultInfo[0].email", values.email);
      setFieldValue("adultInfo[0].cc_email", values.cc_email);
      setFieldValue("adultInfo[0].manager_email", values.manager_email);
    } else {
      setFieldValue("adultInfo[0].title", "Mr.");
      setFieldValue("adultInfo[0].firstName", "");
      setFieldValue("adultInfo[0].lastName", "");
      setFieldValue("adultInfo[0].mobileCountryFlag", "mm");
      setFieldValue("adultInfo[0].mobilePrefix", "95");
      setFieldValue("adultInfo[0].mobileNo", "");
      setFieldValue("adultInfo[0].email", "");
      setFieldValue("adultInfo[0].cc_email", "");
      setFieldValue("adultInfo[0].manager_email", "");
    }
    setFieldValue("isChecked", value);
  };

  return (
    <React.Fragment>
      <MediaQuery minWidth={1025}>
        <HovercraftCustomerInfoWeb
          returnFlightDetailInfo={props.returnFlightDetailInfo}
          pageName={props.values.pageName}
          {...props}
          handleOpenClose={handleOpenClose}
          showConfirmation={props.values.showConfirmation}
          handleContactInfo={handleContactInfo}
          isLoading={props.values.isLoading}
          pageContent={props.pageContent}
          submitText={props.submitText}
        />
      </MediaQuery>
      <MediaQuery maxWidth={1024}>
        <HovercraftCustomerInfoMobile
          pageName={props.values.pageName}
          {...props}
          handleOpenClose={handleOpenClose}
          handleCloseModal={handleCloseModal}
          showConfirmation={props.values.showConfirmation}
          handleContactInfo={handleContactInfo}
          isLoading={props.values.isLoading}
          pageContent={props.pageContent}
          countryFile={COUNTRIES}
          submitText={props.submitText}
        />
      </MediaQuery>
    </React.Fragment>
  );
};

const normalizeData = values => {
  let dataResult = { data: {} };
  dataResult.data["customer_email"] = values.multiEmail;
  dataResult.data["cc_email"] = values.cc_email;
  dataResult.data["manager_email"] = values.manager_email;
  dataResult.data["customer_name"] = values.firstName + " " + values.lastName;
  dataResult.data["customer_phone"] =
    values.mobilePrefix + "" + values.mobileNo;
  return dataResult;
};

const getLastData = () => JSON.parse(sessionStorage.getItem("last"));
const getName = lastData =>
  lastData && lastData.name ? lastData.name.split(" ") : [];
const getFirstName = name =>
  name.length >= 2 ? name.slice(0, -1).join(" ") : name.slice(-1).join(" ");
const getLastName = name => (name.length < 2 ? "" : name.slice(-1).join(" "));

const HovercraftCustomerDetailForm = compose(
  withRouter,
  withFormik({
    mapPropsToValues: props => {
      let last = getLastData();
      let name = getName(last);
      let phone = last && last.phone ? last.phone : "";
      let getPhone = phone.charAt(0);
      phone = Number(getPhone) === 1 ? phone.substring(1) : phone;

      return {
        gender: "Mr.",
        firstName: getFirstName(name),
        lastName: getLastName(name),
        mobileCountryFlag: "us",
        mobilePrefix: "1",
        mobileNo: phone,
        multiEmail: sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
          ? sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
          : "",
        email: "",
        cc_email: "",
        manager_email: sessionStorage.getItem("manager_email")
          ? sessionStorage.getItem("manager_email")
          : ""
      };
    },

    validationSchema: props => HovercraftValidationSchema(props),

    handleSubmit: (values, props) => {
      props.setFieldValue("isLoading", true);
      let data = normalizeData(values);
      props.props.handleReservation(data.data);
    },

    displayName: "BasicForm"
  })
)(MyForm);

export default HovercraftCustomerDetailForm;
