/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  CircularProgress,
  Select,
  Checkbox
} from "@material-ui/core";
import PhonePreFix from "components/PhonePreFix";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  SPACEX_LOGIN_EMAIL,
  SPACEX_FAMILY_FRIEND_OPTION
} from "config/constants";
import MonthOptionComponent from "../../../components/inputs/MonthOptionComponent";
import DayOptionComponent from "../../../components/inputs/DayOptionComponent";
import YearOptionComponent from "../../../components/inputs/YearOptionComponent";

const FlyCustomerInfoMobile = ({
  countryFile,
  values,
  handleBlur,
  handleChange,
  isLoading,
  handleSubmit,
  errors,
  touched,
  setFieldValue,
  isBrownsville,
  submitText,
  errMsg,
  flightId,
  flightArr,
  isCharter,
  isMultiple,
  userData
}) => {
  const [showCC, setShowCC] = useState(false);
  const [flightDetail, setFlightDetail] = useState(null);
  const [flag, setFlag] = useState(false);
  const [familyValue, setFamilyValue] = useState("Single Employee Booking");
  const [maxDays, setMaxDays] = useState(31);
  const handleMonthChange = event => {
    const selectedMonth = event.target.value;
    let newMaxDays;
    if (selectedMonth === "02") {
      // February
      newMaxDays = 28; // default to 28 days
      if (values.dobYear && values.dobYear / 4 === 0) {
        // Leap year
        newMaxDays = 29;
      }
    } else if (["04", "06", "09", "11"].includes(selectedMonth)) {
      // April, June, September, November
      newMaxDays = 30;
    } else {
      newMaxDays = 31;
    }
    setMaxDays(newMaxDays); // update state with new value
  };

  useEffect(() => {
    if (!flag && flightArr.length > 0) {
      const getData = flightArr.find(
        val => Number(val.id) === Number(flightId)
      );
      getData && setFlightDetail(getData);
      setFlag(true);
    }
  }, [flag, flightArr, flightId, setFieldValue]);

  return (
    <React.Fragment>
      <Grid
        container
        style={{
          //position: "fixed",
          overflow: "auto",
          display: "block",
          padding: "0",
          margin: "0",
          top: "0",
          left: "0",
          width: "100%",
          height: "100vh",
          backgroundColor: "#273142"
        }}
      >
        {flightDetail && Number(flightDetail.family_friend) === 1 && (
          <Grid item xs={12} className="familySelectMobile">
            <Select
              native
              value={familyValue}
              onChange={e => {
                setFamilyValue(e.target.value);
                if (e.target.value === SPACEX_FAMILY_FRIEND_OPTION) {
                  setFieldValue("family_friend", 1);
                  //isCharter && setFieldValue("charter", false);
                } else {
                  setFieldValue("family_friend", 0);
                  //isCharter && setFieldValue("charter", true);
                }
              }}
              onBlur={handleBlur}
              inputProps={{
                className: "familySelect"
              }}
              IconComponent={ExpandMoreIcon}
            >
              <option value="Single Employee Booking">
                Single Employee Booking
              </option>
              <option value={SPACEX_FAMILY_FRIEND_OPTION}>
                {SPACEX_FAMILY_FRIEND_OPTION}
              </option>
            </Select>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          className="contactMobileTitle"
          style={{ backgroundColor: "#273142" }}
        >
          <span
            className="title"
            style={{ paddingLeft: "15px", color: "#fff" }}
          >
            Personal Information
          </span>
        </Grid>

        <Grid item xs={12} className="contactMobile" style={{ margin: "5px" }}>
          <Grid item xs={12} style={{ paddingTop: "15px" }}>
            <TextField
              error={errors.firstName && touched.firstName ? true : false}
              id="first-name"
              name="firstName"
              value={
                !isMultiple &&
                userData &&
                userData.first_name &&
                userData.last_name
                  ? userData.first_name
                  : values.firstName
              }
              className={
                !isMultiple &&
                userData &&
                userData.first_name &&
                userData.last_name
                  ? "auto-filled"
                  : ""
              }
              onBlur={handleBlur}
              label={"First Name (must match ID)"}
              onChange={handleChange}
              helperText={
                errors.firstName && touched.firstName ? errors.firstName : ""
              }
              autoComplete="off"
              required={true}
            />
          </Grid>
          <Grid item xs={12} style={{}}>
            <TextField
              error={errors.lastName && touched.lastName ? true : false}
              id="last-name"
              name="lastName"
              value={
                !isMultiple &&
                userData &&
                userData.last_name &&
                userData.first_name
                  ? userData.last_name
                  : values.lastName
              }
              className={
                !isMultiple &&
                userData &&
                userData.first_name &&
                userData.last_name
                  ? "auto-filled"
                  : ""
              }
              label={"Last Name (must match ID)"}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.lastName && touched.lastName ? errors.lastName : ""
              }
              autoComplete="off"
              required={true}
            />
          </Grid>
          {!isMultiple && (
            <Grid item xs={12}>
              <div
                style={{
                  background: "rgba(255, 204, 0, 0.4)",
                  textAlign: "center",
                  padding: "6px 0px",
                  marginTop: "6px",
                  borderRadius: "4px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  border: "1px solid rgb(255, 204, 0)",
                  marginBottom: "50px"
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    color: "rgb(0, 0, 0)",
                    float: "none",
                    lineHeight: "32px",
                    margin: "0px 10px 0px 0px",
                    textAlign: "initial"
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      fontSize: "12px",
                      lineHeight: "18px"
                    }}
                  >
                    {" "}
                    <span
                      style={{
                        display: "inline-block",
                        fontSize: "32px",
                        lineHeight: "29px",
                        width: "32px",
                        height: "32px",
                        padding: "0px 6px 0px 6px",
                        color: "rgb(128, 0, 0)"
                      }}
                    >
                      ⚠
                    </span>
                    If your name does not match your government-issued ID, don't
                    worry, our team can update it for you.
                    <br />
                    please contact <b>flights@spacex.com</b>&nbsp;after you have
                    completed your booking.
                  </div>
                </div>
              </div>
            </Grid>
          )}
          <div className="mobileContainer">
            <div className="mobilePrefixDiv">
              <PhonePreFix
                countryFile={countryFile}
                setFieldValue={setFieldValue}
                inputName="mobilePrefix"
                value={values.mobilePrefix}
              />
            </div>
            <div className="mobileNumberDiv">
              <TextField
                error={errors.mobileNo && touched.mobileNo ? true : false}
                id="mobile_number"
                name="mobileNo"
                value={values.mobileNo}
                label={"Mobile Number"}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors.mobileNo && touched.mobileNo ? errors.mobileNo : ""
                }
                InputProps={{
                  required: true
                }}
                InputLabelProps={{
                  required: true,
                  shrink: false
                }}
                autoComplete="off"
              />
            </div>
          </div>

          {isBrownsville && (
            <TextField
              className="report-purpose"
              error={
                errors.length_of_stay && touched.length_of_stay ? true : false
              }
              id="length-of-stay"
              name="length_of_stay"
              value={values.length_of_stay}
              label={"Length of Stay"}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.length_of_stay && touched.length_of_stay
                  ? errors.length_of_stay
                  : "Nights"
              }
              type="number"
              autoComplete="off"
              InputProps={{ inputProps: { min: 1 } }}
              required={true}
            />
          )}

          <div className="emailContainer mobile-email-region">
            <div className="mobile-first-email">
              <TextField
                error={errors.email && touched.email ? true : false}
                id="first-email"
                name="first-email"
                value={
                  sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
                    ? `${sessionStorage.getItem(SPACEX_LOGIN_EMAIL)}`
                    : ""
                }
                label={"Email"}
                InputProps={{
                  readOnly: true
                }}
                autoComplete="off"
              />
              {!showCC && (
                <Button
                  size="small"
                  className="cc-email"
                  onClick={() => setShowCC(true)}
                >
                  CC
                </Button>
              )}
            </div>
          </div>
          {showCC && (
            <TextField
              className="report-purpose"
              id="second-email"
              name="cc_email"
              value={values.cc_email}
              onBlur={handleBlur}
              label={"CC"}
              onChange={handleChange}
              autoComplete="off"
              helperText="updates will also be send to this email address"
            />
          )}

          {values.family_friend === 0 && (
            <div className="emailContainer mobile-email-region">
              <div className="mobile-manager-email">
                <TextField
                  error={errors.email && touched.email ? true : false}
                  id="manager-email"
                  name="manager-email"
                  value={values.manager_email}
                  label={"Manager's Email"}
                  autoComplete="off"
                  onChange={e => setFieldValue("manager_email", e.target.value)}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          )}

          {isCharter && values.family_friend === 0 && (
            <div className="charter-region-mobile">
              <h4 className="charterTitleMobile">Charter Information</h4>
              <span
                className={`charterDobMobile ${
                  (errors.dobDay && touched.dobDay) ||
                  (errors.dobMonth && touched.dobMonth) ||
                  (errors.dobYear && touched.dobYear)
                    ? "required"
                    : ""
                }`}
              >
                Date of Birth <span className="required">*</span>
              </span>
              <Grid
                container
                style={{
                  paddingBottom: "25px",
                  paddingTop: "5px",
                  width: "95%"
                }}
              >
                <Grid item xs={5}>
                  <select
                    name="dobMonth"
                    className={"selectBox"}
                    required
                    value={values.dobMonth}
                    onChange={e => {
                      setFieldValue("dobMonth", e.target.value);
                      handleMonthChange(e);
                    }}
                  >
                    <MonthOptionComponent />
                  </select>
                </Grid>
                <Grid item xs={3}>
                  <select
                    name="dobDay"
                    value={values.dobDay}
                    onChange={e => setFieldValue("dobDay", e.target.value)}
                    className={"selectBox"}
                    required
                  >
                    <DayOptionComponent dayOption={maxDays} />
                  </select>
                </Grid>
                <Grid item xs={4}>
                  <select
                    className={"selectBox"}
                    name="dobYear"
                    value={values.dobYear}
                    onChange={e => {
                      setFieldValue("dobYear", e.target.value);
                    }}
                    onBlur={handleBlur}
                  >
                    <YearOptionComponent />
                  </select>
                </Grid>
                {((errors.dobDay && touched.dobDay) ||
                  (errors.dobMonth && touched.dobMonth) ||
                  (errors.dobYear && touched.dobYear)) && (
                  <span className="errMessage">
                    {errors.dobDay || errors.dobMonth || errors.dobYear}
                  </span>
                )}
              </Grid>
              {/* <SingleDatePicker
                id="dob"
                date={values.dob === "" ? null : values.dob}
                placeholder="MM/DD/YYYY"
                focused={dateFocus}
                onFocusChange={() => setDateFocus(true)}
                onDateChange={date => setFieldValue("dob", moment(date))}
                numberOfMonths={1}
                navPrev={<div className="arrow-left" id="prevMonth"></div>}
                navNext={<div className="arrow-right" id="nextMonth"></div>}
                displayFormat="MM/DD/YYYY"
                hideKeyboardShortcutsPanel={true}
                keepOpenOnDateSelect={false}
                onClose={() => setDateFocus(false)}
                daySize={30}
                transitionDuration={0}
                isOutsideRange={() => false}
                readOnly
                renderMonthElement={RenderMonthElement}
              /> */}

              <TextField
                error={errors.weight && touched.weight ? true : false}
                id="standard-name"
                name="weight"
                type="text"
                value={values.weight}
                onBlur={handleBlur}
                label={"Personal Weight (lbs)"}
                onChange={handleChange}
                autoComplete="off"
                InputLabelProps={{
                  required: true,
                  shrink: false
                }}
              />
              <TextField
                error={errors.baggage && touched.baggage ? true : false}
                id="standard-name"
                name="baggage"
                type="text"
                value={values.baggage}
                onBlur={handleBlur}
                label={"Baggage Weight (lbs)"}
                onChange={handleChange}
                autoComplete="off"
                InputLabelProps={{
                  required: true,
                  shrink: false
                }}
              />
            </div>
          )}

          {values.family_friend === 0 && (
            <TextField
              className="report-purpose"
              error={errors.report && touched.report ? true : false}
              id="standard-name"
              name="report"
              value={values.report}
              onBlur={handleBlur}
              label={"Who will you report to at your destination?"}
              onChange={handleChange}
              autoComplete="off"
              helperText={
                errors.report && touched.report
                  ? errors.report
                  : "Your project manager on site (not existing manager)"
              }
              required={true}
            />
          )}

          {values.family_friend === 0 && (
            <TextField
              className="report-purpose"
              error={errors.purpose && touched.purpose ? true : false}
              id="standard-name"
              name="purpose"
              value={values.purpose}
              onBlur={handleBlur}
              label={"How does this visit advance SpaceX?"}
              placeholder="ex: working on Starship SN5 harness design and build."
              onChange={handleChange}
              autoComplete="off"
              helperText={
                errors.purpose && touched.purpose
                  ? errors.purpose
                  : "Describe in a brief but complete sentence."
              }
              required={true}
            />
          )}
          {values.family_friend === 0 &&
            flightDetail &&
            flightDetail.shuttle_bus === "1" && (
              <div className="shuttleBox">
                <Checkbox
                  value={values.shuttle_bus}
                  checked={values.shuttle_bus}
                  color="primary"
                  inputProps={{
                    "aria-label": "secondary checkbox"
                  }}
                  onChange={(e, v) => setFieldValue("shuttle_bus", v)}
                />{" "}
                <span>Interested in shuttle bus service if available</span>
              </div>
            )}

          {/* <TextField
            error={
              errors.inventory_weight && touched.inventory_weight ? true : false
            }
            id="standard-name"
            name="inventory_weight"
            type="number"
            value={values.inventory_weight}
            onBlur={handleBlur}
            label={"Personal Baggage (lbs)"}
            onChange={handleChange}
            autoComplete="off"
            InputLabelProps={{
              required: true,
              shrink: false
            }}
          /> */}

          {/* <div className="mobilebaggageContainer">
            <div className="baggage_div baggage_height">
              <TextField
                id="inventory_dimension_h"
                name="inventory_dimension_h"
                type="number"
                value={values.inventory_dimension_h}
                onBlur={handleBlur}
                label={"height"}
                onChange={handleChange}
                autoComplete="off"
              />
              <span className="baggage_mobile_multiply">x</span>
            </div>
            <div className="baggage_div baggage_width">
              <TextField
                id="inventory_dimension_w"
                name="inventory_dimension_w"
                type="number"
                value={values.inventory_dimension_w}
                onBlur={handleBlur}
                label={"width"}
                onChange={handleChange}
                autoComplete="off"
              />
              <span className="baggage_mobile_multiply">x</span>
            </div>
            <div className="baggage_div baggage_depth">
              <TextField
                id="inventory_dimension_l"
                name="inventory_dimension_l"
                type="number"
                value={values.inventory_dimension_l}
                onBlur={handleBlur}
                label={"depth"}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
            <div className="baggage_div baggage_type">
              <div className="baggage_type_div MuiFormControl-root">
                <label>&nbsp;</label>
                <span className="baggage_type_text">ft</span>
              </div>
            </div>
          </div> */}

          {/* <div className="additional_baggage_div">
            <label>Add additional oversized baggage?</label>
            <div className="additional_select_mobile">
              <select
                className="selectBox additionalSelect"
                value={additionalBaggage}
                onChange={handleAdditionalBaggage}
              >
                <option value={true}>yes</option>
                <option value={false}>no</option>
              </select>
            </div>
            <div className="additional_baggage_mobile">
              {additionalBaggage && (
                <>
                  <TextField
                    name="additional_weight"
                    type="number"
                    value={values.additional_weight}
                    onBlur={handleBlur}
                    label={""}
                    onChange={handleChange}
                    autoComplete="off"
                    InputLabelProps={{
                      shrink: false
                    }}
                  />
                  <span>lbs</span>
                </>
              )}
            </div>
          </div>

          {additionalBaggage && (
            <div className="mobilebaggageContainer">
              <div className="baggage_div baggage_height">
                <TextField
                  name="additional_dimension_h"
                  type="number"
                  value={values.additional_dimension_h}
                  onBlur={handleBlur}
                  label={"height"}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <span className="baggage_mobile_multiply">x</span>
              </div>
              <div className="baggage_div baggage_width">
                <TextField
                  name="additional_dimension_w"
                  type="number"
                  value={values.additional_dimension_w}
                  onBlur={handleBlur}
                  label={"width"}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <span className="baggage_mobile_multiply">x</span>
              </div>
              <div className="baggage_div baggage_depth">
                <TextField
                  name="additional_dimension_l"
                  type="number"
                  value={values.additional_dimension_l}
                  onBlur={handleBlur}
                  label={"depth"}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
              <div className="baggage_div baggage_type">
                <div className="baggage_type_div MuiFormControl-root">
                  <label>&nbsp;</label>
                  <span className="baggage_type_text">ft</span>
                </div>
              </div>
            </div>
          )}

          <div className="baggage_mobileq">
            <div className="baggage_mobileq_div">
              <select
                className="selectBox"
                name="hazardous"
                value={values.hazardous}
                onChange={handleChange}
              >
                <option value=""></option>
                <option value="1">yes</option>
                <option value="0">no</option>
              </select>
              <span>
                Does any of your baggage contain hazardous or flammable items?
              </span>
            </div>
          </div> */}
        </Grid>

        {values.family_friend === 1 && (
          <>
            <span
              style={{
                color: "orange",
                display: "block",
                marginBottom: "30px",
                marginTop: "10px"
              }}
            >
              ⚠️ Please note that the above <strong>does NOT count</strong> as
              an employee reservation. If you are planning on travelling you
              must make a <strong>separate employee reservation</strong>.
            </span>
            <h4 className="familyTitle mobile">Family/Friend Information</h4>
            <span
              style={{
                color: "orange",
                display: "block",
                marginBottom: "10px"
              }}
            >
              ⚠️ Please read the{" "}
              <a
                style={{
                  color: "yellow",
                  fontWeight: "bold"
                }}
                href="https://spacexgcchigh.sharepoint.us/:w:/s/Jet/Ech7BusD2C1MggKVIshYfYIBc2OsYCNhW3wpnWgrE1Sbyg?e=5b79Or"
                target="_blank"
                rel="noopener noreferrer"
              >
                Friends & Family Flights FAQ
              </a>{" "}
              for a how-to and frequently asked questions about booking flights
              for your guests.
            </span>
            <span
              style={{
                color: "orange",
                display: "block",
                marginBottom: "10px"
              }}
            >
              ⚠️ Please understand that employee travel will always be
              prioritized. This means that whenever you book a friends/family
              flight,&nbsp;
              <strong>
                <i>it is never guaranteed</i>
              </strong>
              .
            </span>
            <Grid container className="familyFieldsMobile contactMobile">
              <Grid
                item
                xs={12}
                className={
                  errors.family_first_name && touched.family_first_name
                    ? "searchErrMessage"
                    : ""
                }
              >
                <TextField
                  error={
                    errors.family_first_name && touched.family_first_name
                      ? true
                      : false
                  }
                  name="family_first_name"
                  type="text"
                  label={"First Name"}
                  value={values.family_first_name}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Family or Friend first name*"
                  InputProps={{
                    required: true,
                    className: "familyInput familyName"
                  }}
                  InputLabelProps={{
                    required: true,
                    shrink: false
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={
                  errors.family_last_name && touched.family_last_name
                    ? "searchErrMessage"
                    : ""
                }
              >
                <TextField
                  error={
                    errors.family_last_name && touched.family_last_name
                      ? true
                      : false
                  }
                  name="family_last_name"
                  type="text"
                  label={"Last Name"}
                  value={values.family_last_name}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Family or Friend last name*"
                  InputProps={{
                    required: true,
                    className: "familyInput familyName"
                  }}
                  InputLabelProps={{
                    required: true,
                    shrink: false
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={
                  errors.family_relationship && touched.family_relationship
                    ? "searchErrMessage"
                    : ""
                }
              >
                <TextField
                  error={
                    errors.family_relationship && touched.family_relationship
                      ? true
                      : false
                  }
                  name="family_relationship"
                  type="text"
                  label={"Relationship"}
                  value={values.family_relationship}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Relationship*"
                  InputProps={{
                    required: true,
                    className: "familyInput"
                  }}
                  InputLabelProps={{
                    required: true,
                    shrink: false
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={
                  errors.family_email && touched.family_email
                    ? "searchErrMessage"
                    : ""
                }
              >
                <TextField
                  error={
                    errors.family_email && touched.family_email ? true : false
                  }
                  name="family_email"
                  type="text"
                  value={values.family_email}
                  autoComplete="off"
                  label={"Email"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Family or Friend email*"
                  InputProps={{
                    required: true,
                    className: "familyInput"
                  }}
                  InputLabelProps={{
                    required: true,
                    shrink: false
                  }}
                />
              </Grid>
              <div
                className={`${
                  errors.family_phone && touched.family_phone
                    ? "searchErrMessage"
                    : ""
                } mobileContainer`}
              >
                <div className="mobilePrefixDiv">
                  <PhonePreFix
                    countryFile={countryFile}
                    setFieldValue={setFieldValue}
                    inputName="mobilePrefix"
                    value={values.family_phonePrefix}
                  />
                </div>
                <div className="mobileNumberDiv">
                  <TextField
                    error={
                      errors.family_phone && touched.family_phone ? true : false
                    }
                    id="mobile_number"
                    name="family_phone"
                    value={values.family_phone}
                    label={"Phone number"}
                    placeholder="Phone number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      required: true,
                      className: "familyInput"
                    }}
                    InputLabelProps={{
                      required: true,
                      shrink: false
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="charter-region-mobile">
                <h4 className="charterTitleMobile">
                  {isCharter ? "Charter Information" : "Required Information"}
                </h4>
                <span
                  className={`charterDobMobile ${
                    errors.dob && touched.dob ? "required" : ""
                  }`}
                >
                  Date of Birth <span className="required">*</span>
                </span>
                <Grid
                  container
                  style={{
                    paddingBottom: "25px",
                    paddingTop: "5px",
                    width: "95%"
                  }}
                >
                  <Grid item xs={5}>
                    <select
                      name="dobMonth"
                      className={"selectBox"}
                      required
                      value={values.dobMonth}
                      onChange={e => {
                        setFieldValue("dobMonth", e.target.value);
                        handleMonthChange(e);
                      }}
                    >
                      <MonthOptionComponent />
                    </select>
                  </Grid>
                  <Grid item xs={3}>
                    <select
                      name="dobDay"
                      value={values.dobDay}
                      onChange={e => setFieldValue("dobDay", e.target.value)}
                      className={"selectBox"}
                      required
                    >
                      <DayOptionComponent dayOption={maxDays} />
                    </select>
                  </Grid>
                  <Grid item xs={4}>
                    <select
                      className={"selectBox"}
                      name="dobYear"
                      value={values.dobYear}
                      onChange={e => {
                        setFieldValue("dobYear", e.target.value);
                      }}
                      onBlur={handleBlur}
                    >
                      <YearOptionComponent />
                    </select>
                  </Grid>
                  {((errors.dobDay && touched.dobDay) ||
                    (errors.dobMonth && touched.dobMonth) ||
                    (errors.dobYear && touched.dobYear)) && (
                    <span className="errMessage">
                      {errors.dobDay || errors.dobMonth || errors.dobYear}
                    </span>
                  )}
                </Grid>
                {/* <SingleDatePicker
                  id="dob"
                  date={values.dob === "" ? null : values.dob}
                  placeholder="MM/DD/YYYY"
                  focused={dateFocus}
                  onFocusChange={() => setDateFocus(true)}
                  onDateChange={date => setFieldValue("dob", moment(date))}
                  numberOfMonths={1}
                  navPrev={<div className="arrow-left" id="prevMonth"></div>}
                  navNext={<div className="arrow-right" id="nextMonth"></div>}
                  displayFormat="MM/DD/YYYY"
                  hideKeyboardShortcutsPanel={true}
                  keepOpenOnDateSelect={false}
                  onClose={() => setDateFocus(false)}
                  daySize={30}
                  transitionDuration={0}
                  isOutsideRange={() => false}
                  readOnly
                  renderMonthElement={RenderMonthElement}
                /> */}
                {isCharter && (
                  <TextField
                    error={errors.weight && touched.weight ? true : false}
                    id="standard-name"
                    name="weight"
                    type="text"
                    value={values.weight}
                    onBlur={handleBlur}
                    label={"Personal Weight (lbs)"}
                    onChange={handleChange}
                    autoComplete="off"
                    InputLabelProps={{
                      required: true,
                      shrink: false
                    }}
                  />
                )}
                {isCharter && (
                  <TextField
                    error={errors.baggage && touched.baggage ? true : false}
                    id="standard-name"
                    name="baggage"
                    type="text"
                    value={values.baggage}
                    onBlur={handleBlur}
                    label={"Baggage Weight (lbs)"}
                    onChange={handleChange}
                    autoComplete="off"
                    InputLabelProps={{
                      required: true,
                      shrink: false
                    }}
                  />
                )}
              </div>
              {flightDetail && flightDetail.shuttle_bus === "1" && (
                <Grid item xs={12} className="shuttleBox">
                  <Checkbox
                    value={values.shuttle_bus}
                    checked={values.shuttle_bus}
                    color="primary"
                    inputProps={{
                      "aria-label": "secondary checkbox"
                    }}
                    onChange={(e, v) => setFieldValue("shuttle_bus", v)}
                  />{" "}
                  <span>Interested in shuttle bus service if available</span>
                </Grid>
              )}
            </Grid>
          </>
        )}

        {flightDetail && Number(flightDetail.animal) === 1 && (
          <Grid item sm={12} className="animalCheckMobile">
            <Checkbox
              value={values.animal}
              checked={values.animal}
              color="primary"
              inputProps={{
                "aria-label": "secondary checkbox"
              }}
              onChange={(e, v) => setFieldValue("animal", v)}
            />{" "}
            +1 Service Animal or Pet
          </Grid>
        )}
        {values.animal && (
          <Grid container className="animalFieldsMobile">
            <Grid
              item
              xs={12}
              className={
                errors.animal_type && touched.animal_type
                  ? "searchErrMessage"
                  : ""
              }
            >
              <span className="fieldTitle">
                Animal Type<span className="required">*</span>
              </span>
              <Select
                native
                onChange={e => setFieldValue("animal_type", e.target.value)}
                IconComponent={ExpandMoreIcon}
                className="animalType"
                value={values.animal_type}
              >
                <option value=""></option>
                <option value="cat">Cat</option>
                <option value="dog">Dog</option>
                <option value="other">Other</option>
              </Select>
            </Grid>
            <Grid
              item
              xs={12}
              className={
                errors.animal_size && touched.animal_size
                  ? "searchErrMessage"
                  : ""
              }
            >
              <span className="fieldTitle">
                Animal Size<span className="required">*</span>
              </span>
              <Select
                native
                onChange={e => setFieldValue("animal_size", e.target.value)}
                IconComponent={ExpandMoreIcon}
                className="animalSize"
                value={values.animal_size}
              >
                <option value=""></option>
                <option value="small">Small</option>
                <option value="medium">Medium</option>
                <option value="large">Large</option>
              </Select>
            </Grid>
            <Grid
              item
              xs={12}
              className={
                errors.animal_name && touched.animal_name
                  ? "searchErrMessage"
                  : ""
              }
            >
              <span className="fieldTitle">
                Animal Name<span className="required">*</span>
              </span>
              <TextField
                name="animal_name"
                type="text"
                value={values.animal_name}
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Animal Name*"
                inputProps={{
                  className: "animalName"
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              className={
                errors.animal_service && touched.animal_service
                  ? "searchErrMessage"
                  : ""
              }
            >
              <span className="fieldTitle">
                Animal Service (Yes/No)<span className="required">*</span>
              </span>
              <Select
                native
                onChange={e => setFieldValue("animal_service", e.target.value)}
                IconComponent={ExpandMoreIcon}
                className="animalSize"
                value={values.animal_service}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Select>
            </Grid>
            <Grid
              item
              xs={12}
              className={
                errors.animal_weight && touched.animal_weight
                  ? "searchErrMessage"
                  : ""
              }
            >
              <span className="fieldTitle">
                Animal Weight (lbs)<span className="required">*</span>
              </span>
              <TextField
                name="animal_weight"
                type="text"
                value={values.animal_weight}
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Animal Weight*"
                inputProps={{
                  className: "animalName"
                }}
              />
            </Grid>
            { values.animal_service === "yes" &&  <Grid
              item
              xs={12}
              className={
                errors.animal_id && touched.animal_id
                  ? "searchErrMessage"
                  : ""
              }
            >
              <span className="fieldTitle">
                Animal Service ID<span className="required">*</span>
              </span>
              <TextField
                name="animal_id"
                type="text"
                value={values.animal_id}
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Animal ID*"
                inputProps={{
                  className: "animalName"
                }}
              />
            </Grid>}
            <Grid
              item
              xs={12}
              // className={
              //   errors.animal_detail && touched.animal_detail
              //     ? "searchErrMessage"
              //     : ""
              // }
            >
              <span className="fieldTitle">
                Animal Details
                {/* <span className="required">*</span> */}
              </span>
              <textarea
                name="animal_detail"
                rows={4}
                className="animal-detail"
                placeholder="service animal or pet details*"
                onChange={handleChange}
              ></textarea>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          className="continueBox"
          style={{
            background: "transparent",
            border: "0px"
          }}
        >
          {errMsg !== "" && (
            <span
              style={{
                color: "#fff",
                fontSize: "12px",
                display: "inline-block",
                width: "100%",
                textAlign: "right"
              }}
            >
              {errMsg}
            </span>
          )}
          <div
            style={{
              color: "orange",
              marginTop: "15px",
              display: "block",
              marginRight: "15px",
              fontSize: "15px"
            }}
          >
            {values.family_friend === 1 && (
              <>
                Each SpaceX employee is allowed up to 3 guests to travel
                roundtrip at a time.
                <br />
                <br />
              </>
            )}
            <strong>Optional: &nbsp;</strong>
            <br />
            You may wear a mask on the aircraft and inside the airport if you so
            choose.
          </div>
          <Button
            className="btnContinue pull-right flyInfoBtnMobile"
            id="fl-customer-btn-continue"
            // onClick={() => {
            //   handleClickContinue(true);
            //   props.validateForm().then(err => {
            //     if (Object.keys(err).length === 0) {
            //       props.setFieldValue("showConfirmation", true);
            //     } else {
            //       props.setTouched(err);
            //     }
            //   });
            // }}
            onClick={handleSubmit}
            disabled={isLoading ? true : false}
            style={{ marginBottom: "10px" }}
          >
            {submitText} {isLoading && <CircularProgress />}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FlyCustomerInfoMobile;
