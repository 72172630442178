import * as Yup from "yup";

const SleepCustomerValidationSchema = props => {
  return Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    mobileNo: Yup.string().required("Phone is required."),
    email: Yup.string()
      .email("Please enter valid email address.")
      .required("Email is required."),
    avgWork: Yup.string().when("family_friend", {
      is: 0,
      then: Yup.string().required("Average shift work is required.")
    }),
    family_first_name: Yup.string().when("family_friend", {
      is: 1,
      then: Yup.string().required("Required")
    }),
    family_last_name: Yup.string().when("family_friend", {
      is: 1,
      then: Yup.string().required("Required")
    }),
    // family_relationship: Yup.string().when("family_friend", {
    //   is: 1,
    //   then: Yup.string().required("Required")
    // }),
    family_email: Yup.string().when("family_friend", {
      is: 1,
      then: Yup.string()
        .email(({ value }) => `${value} is not a valid email`)
        .required("Required")
    }),
    family_phone: Yup.string().when("family_friend", {
      is: 1,
      then: Yup.string().required("Required")
    }),

    dobDay: Yup.string().when("family_friend", {
      is: 1,
      then: Yup.string()
        .required("Required")
        .test("dobDay", "Date of Birth is required", value => {
          return value === "00" ? false : true;
        })
    }),

    dobMonth: Yup.string().when("family_friend", {
      is: 1,
      then: Yup.string()
        .required("Required")
        .test("dobMonth", "Date of Birth is required", value => {
          return value === "00" ? false : true;
        })
    }),

    dobYear: Yup.string().when("family_friend", {
      is: 1,
      then: Yup.string()
        .required("Required")
        .test("dobYear", "Date of Birth is required", value => {
          return value === "0000" ? false : true;
        })
    }),

    host_spacex_email:
      props.familyValue === "FriendBNB" &&
      Yup.string().required("Host spacex.com email is required.")
  });
};

export default SleepCustomerValidationSchema;
